import {
  Button,
  ButtonProps,
  Link as MaterialLink,
  LinkProps as MaterialProps,
  Typography,
} from '@mui/material'
import { forwardRef } from 'react'
import { Link as RRDLink } from 'react-router-dom'

export type LinkProps = MaterialProps & {
  to: string
}
export const Link = (props: LinkProps) => (
  <MaterialLink component={RRDLink} {...props} underline="none" />
)

export type ButtonLinkProps = ButtonProps & {
  to: string
  linkProps?: MaterialProps
}
export const ButtonLink = forwardRef<HTMLButtonElement, ButtonLinkProps>(
  function ButtonLink(props, ref) {
    const { linkProps, to, children, ...rest } = props
    if (props.disabled) return <Button {...rest}>{children}</Button>
    return (
      <Link {...linkProps} to={to} underline="none">
        <Button {...rest} ref={ref}>
          {children}
        </Button>
      </Link>
    )
  }
)

export type LinkHrefProps = MaterialProps & {
  disabled?: boolean
  href: string
}

/**
 * We are creating this "duplicate" component so that material ui props with
 * "LinkComponent" will have the correct signature
 *
 * Other than that please don't use them
 */
export const LinkHref = forwardRef<HTMLButtonElement, LinkHrefProps>(
  function ButtonLink(props, ref) {
    const { href, children, ...rest } = props
    if (props.disabled)
      return (
        <Typography underline="none" {...rest}>
          {children}
        </Typography>
      )

    return (
      <Link to={href} underline="none" {...rest}>
        {children}
      </Link>
    )
  }
)
