import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  Organization,
} from '@/utils/api'
import graphQLRequest from '@/utils/graphqlClient'

const releaseQuery = /* GraphQL */ `
  {
    version
    name
    releaseNotes
    id
    createdAt
    createdBy {
      email
    }
    lowerLinks {
      name
      url
      componentLink
    }
    lowerReleaseBundle
    upperReleaseBundle
    upperLinks {
      name
      url
      componentLink
    }
    selectedSolutions {
      items {
        vailHistory {
          id
          name
          data
        }
      }
    }
  }
`
const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      organizationProdReleaseId
      organizationStagingReleaseId
      prodRelease ${releaseQuery}
      stagingRelease ${releaseQuery}
      name
      lastRelease
      orgStatus
      languagesSupported
      privacyPolicy
      slug
      tags
      termsOfUse
      updatedBy {
        email
        name
        role
        demosShown
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      id
      createdAt
      updatedAt
      organizationUpdatedById
      phoneNumber
      solutionDeployed
      __typename
    }
  }
`

export const useOrg = (orgID?: string) => {
  const params = useParams()
  const safeOrgId = orgID || params.orgID
  return useQuery({
    enabled: !!safeOrgId,
    queryFn: async () => {
      const org = await graphQLRequest<
        GetOrganizationQuery,
        GetOrganizationQueryVariables
      >(getOrganization, { id: safeOrgId as string })
      if (org.data?.getOrganization === null) throw new Error('Org not found')
      if (org.data?.getOrganization?.orgStatus === 'active')
        return org.data?.getOrganization as Organization
      throw new Error('Org is not active')
    },

    queryKey: ['organizations', safeOrgId],
  })
}
