import { useTheme } from '@mui/material'
import { NamedColorspace } from '@textea/json-viewer'
import { useMemo } from 'react'

export const useJsonVailTheme = () => {
  const theme = useTheme()
  const vailTheme: NamedColorspace = useMemo(() => {
    const getTheme = (light: string, dark: string) =>
      theme.palette.mode === 'light' ? light : dark
    return {
      author: 'LifelinkSystems',
      base00: getTheme(theme.palette.grey[50], theme.palette.grey[900]),
      base01: '#343d46',
      base02: '#4f5b66',
      base03: '#65737e',
      base04: '#a7adba',
      base05: '#c0c5ce',
      base06: '#dfe1e8',
      base07: theme.palette.text.primary,
      base08: theme.palette.text.secondary,
      base09: theme.palette.text.secondary,
      base0A: '#ebcb8b',
      base0B: '#a3be8c',
      base0C: '#96b5b4',
      base0D: '#8fa1b3',
      base0E: '#b48ead',
      base0F: '#ab7967',
      scheme: 'Vail',
    }
  }, [theme])
  return vailTheme
}
