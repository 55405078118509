/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type solutionMetadata = {
  historyId: string,
  solutionId: string,
};

export enum PromptSetTypes {
  promptTemplate = "promptTemplate",
  chatTemplate = "chatTemplate",
}


export type ChatPromptInput = {
  response: string,
  role: ChatPromptRole,
};

export enum ChatPromptRole {
  user = "user",
  assistant = "assistant",
}


export type DeleteVailEngagementGoalInput = {
  id: string,
};

export type ModelVailEngagementGoalConditionInput = {
  admins?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  priority?: ModelPriorityInput | null,
  archived?: ModelVisibilityInput | null,
  spec?: ModelStringInput | null,
  notification?: ModelStringInput | null,
  initialConversation?: ModelBooleanInput | null,
  and?: Array< ModelVailEngagementGoalConditionInput | null > | null,
  or?: Array< ModelVailEngagementGoalConditionInput | null > | null,
  not?: ModelVailEngagementGoalConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationEngagementGoalsId?: ModelIDInput | null,
  vailEngagementGoalUpdatedById?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelPriorityInput = {
  eq?: Priority | null,
  ne?: Priority | null,
};

export enum Priority {
  low = "low",
  high = "high",
}


export type ModelVisibilityInput = {
  eq?: Visibility | null,
  ne?: Visibility | null,
};

export enum Visibility {
  archived = "archived",
  active = "active",
}


export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type VailEngagementGoal = {
  __typename: "VailEngagementGoal",
  admins?: string | null,
  editors?: string | null,
  readers?: string | null,
  history?: ModelVailHistoryConnection | null,
  org?: Organization | null,
  updatedBy?: CognitoUser | null,
  name: string,
  description: string,
  priority: Priority,
  script?:  Array<ReleaseBundle > | null,
  creation?: CreationEG | null,
  archived?: Visibility | null,
  spec: string,
  cards?: ModelEgCardConnection | null,
  notification?: string | null,
  initialConversation?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationEngagementGoalsId?: string | null,
  vailEngagementGoalUpdatedById?: string | null,
};

export type ModelVailHistoryConnection = {
  __typename: "ModelVailHistoryConnection",
  items:  Array<VailHistory | null >,
  nextToken?: string | null,
};

export type VailHistory = {
  __typename: "VailHistory",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  createdBy?: CognitoUser | null,
  data: string,
  name: string,
  notes?: string | null,
  platformKeys?: PlatformKeys | null,
  lastDeployedLower?: string | null,
  lastDeployedUpper?: string | null,
  org?: Organization | null,
  releases?: ModelReleaseHistoriesConnection | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  vailEngagementGoalHistoryId?: string | null,
  vailFAQHistoryId?: string | null,
  organizationHistoryId?: string | null,
  vailSolutionHistoryId?: string | null,
  recipeHistoryId?: string | null,
  customComponentHistoryId?: string | null,
  vailHistoryCreatedById?: string | null,
};

export type CognitoUser = {
  __typename: "CognitoUser",
  owner?: string | null,
  email: string,
  name: string,
  role: Permission,
  settings?: UserSettings | null,
  demosShown?: Array< string > | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export enum Permission {
  administrator = "administrator",
  disabled = "disabled",
  user = "user",
}


export type UserSettings = {
  __typename: "UserSettings",
  fontSize?: number | null,
  theme?: Theme | null,
  recipeDelay?: number | null,
};

// List of supported themes in vail
export enum Theme {
  dark = "dark",
  light = "light",
}


export type PlatformKeys = {
  __typename: "PlatformKeys",
  lowerEnv?: string | null,
  middleEnv?: string | null,
  upperEnv?: string | null,
};

export type Organization = {
  __typename: "Organization",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  solution?: ModelVailSolutionConnection | null,
  FAQ?: ModelVailFAQConnection | null,
  engagementGoals?: ModelVailEngagementGoalConnection | null,
  history?: ModelVailHistoryConnection | null,
  name: string,
  orgStatus?: orgStatus | null,
  privacyPolicy?: string | null,
  recipe?: ModelRecipeConnection | null,
  egCards?: ModelEgCardConnection | null,
  prodRelease?: VailRelease | null,
  stagingRelease?: VailRelease | null,
  slug: string,
  tags: Array< string >,
  termsOfUse?: string | null,
  phoneNumber?: string | null,
  customComponents?: ModelCustomComponentConnection | null,
  updatedBy?: CognitoUser | null,
  promptSets?: ModelPromptSetConnection | null,
  namespaces?: ModelNamespaceConnection | null,
  documents?: ModelDocumentConnection | null,
  solutionDeployed?: string | null,
  languagesSupported?: Array< string > | null,
  lastRelease?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationProdReleaseId?: string | null,
  organizationStagingReleaseId?: string | null,
  organizationUpdatedById?: string | null,
};

export type ModelVailSolutionConnection = {
  __typename: "ModelVailSolutionConnection",
  items:  Array<VailSolution | null >,
  nextToken?: string | null,
};

export type VailSolution = {
  __typename: "VailSolution",
  editors?: string | null,
  readers?: string | null,
  history?: ModelVailHistoryConnection | null,
  org?: Organization | null,
  updatedBy?: CognitoUser | null,
  name: string,
  type: SolutionType,
  description?: string | null,
  entryPoint: string,
  components?:  Array<ReleaseBundle > | null,
  usesYML?: boolean | null,
  script?: string | null,
  engagementGoals?: ModelEgCardConnection | null,
  lastDeployed?: string | null,
  visibility?: Visibility | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationSolutionId?: string | null,
  vailSolutionUpdatedById?: string | null,
};

export enum SolutionType {
  basic = "basic",
  advanced = "advanced",
}


export type ReleaseBundle = {
  __typename: "ReleaseBundle",
  name: string,
  version: string,
  type: string,
  extra?: string | null,
};

export type ModelEgCardConnection = {
  __typename: "ModelEgCardConnection",
  items:  Array<EgCard | null >,
  nextToken?: string | null,
};

export type EgCard = {
  __typename: "EgCard",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  engagementGoal: VailEngagementGoal,
  version: VailHistory,
  solution: VailSolution,
  org: Organization,
  id: string,
  createdAt: string,
  updatedAt: string,
  vailEngagementGoalCardsId?: string | null,
  organizationEgCardsId?: string | null,
  vailSolutionEngagementGoalsId?: string | null,
  egCardVersionId: string,
};

export type ModelVailFAQConnection = {
  __typename: "ModelVailFAQConnection",
  items:  Array<VailFAQ | null >,
  nextToken?: string | null,
};

export type VailFAQ = {
  __typename: "VailFAQ",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  autoSuggestConfig: AutoSuggestConfig,
  menuConfig: FAQMenuConfig,
  name: string,
  history?: ModelVailHistoryConnection | null,
  org?: Organization | null,
  questions?:  Array<FAQQuestion > | null,
  updatedBy?: CognitoUser | null,
  active: boolean,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationFAQId?: string | null,
  vailFAQUpdatedById?: string | null,
};

export type AutoSuggestConfig = {
  __typename: "AutoSuggestConfig",
  ignoreWords: Array< string >,
  minimumCharacters: number,
  preSearchPrompt: string,
  questionNotFoundButton: string,
  returnToMainMenuButton: string,
  searchBoxPlaceholder: string,
};

export type FAQMenuConfig = {
  __typename: "FAQMenuConfig",
  answerCompleteButton: Array< string >,
  defaultQuestions: Array< string >,
  disclaimerText: string,
  displayRandomQuestionsInMenu: boolean,
  exitFaqComponentButton: string,
  startSearchButton: string,
  questionNotFoundStep: string,
  faqCompleteNextStep: string,
  menuPrompt: Array< string >,
  persistentQuestion: Array< string >,
};

export type FAQQuestion = {
  __typename: "FAQQuestion",
  answer?:  Array<FAQAnswer > | null,
  disclaimer: boolean,
  key: string,
  id?: string | null,
  keywords: Array< string >,
  question: string,
  suggestedQuestions?: Array< string > | null,
};

export type FAQAnswer = {
  __typename: "FAQAnswer",
  type: FAQAnswerType,
  value: string,
};

export enum FAQAnswerType {
  image = "image",
  text = "text",
  video = "video",
}


export type ModelVailEngagementGoalConnection = {
  __typename: "ModelVailEngagementGoalConnection",
  items:  Array<VailEngagementGoal | null >,
  nextToken?: string | null,
};

export enum orgStatus {
  active = "active",
  inactive = "inactive",
}


export type ModelRecipeConnection = {
  __typename: "ModelRecipeConnection",
  items:  Array<Recipe | null >,
  nextToken?: string | null,
};

export type Recipe = {
  __typename: "Recipe",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  history?: ModelVailHistoryConnection | null,
  org?: Organization | null,
  scriptRecipe: string,
  settings: RecipeSettings,
  updatedBy?: CognitoUser | null,
  scriptCode?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationRecipeId?: string | null,
  recipeUpdatedById?: string | null,
};

export type RecipeSettings = {
  __typename: "RecipeSettings",
  description: string,
  name: string,
};

export type VailRelease = {
  __typename: "VailRelease",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  org?: Organization | null,
  version: string,
  name: string,
  createdBy: CognitoUser,
  releaseNotes: string,
  updatedBy?: CognitoUser | null,
  selectedSolutions?: ModelReleaseHistoriesConnection | null,
  lowerLinks?:  Array<ReleaseLink > | null,
  upperLinks?:  Array<ReleaseLink > | null,
  lowerReleaseBundle?: string | null,
  upperReleaseBundle?: string | null,
  publishedToProd?: boolean | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  vailReleaseCreatedById: string,
  vailReleaseUpdatedById?: string | null,
  vailReleaseOrgId?: string | null,
};

export type ModelReleaseHistoriesConnection = {
  __typename: "ModelReleaseHistoriesConnection",
  items:  Array<ReleaseHistories | null >,
  nextToken?: string | null,
};

export type ReleaseHistories = {
  __typename: "ReleaseHistories",
  id: string,
  vailHistoryID: string,
  vailReleaseID: string,
  vailHistory: VailHistory,
  vailRelease: VailRelease,
  createdAt: string,
  updatedAt: string,
};

export type ReleaseLink = {
  __typename: "ReleaseLink",
  name: string,
  url: string,
  componentLink: string,
};

export type ModelCustomComponentConnection = {
  __typename: "ModelCustomComponentConnection",
  items:  Array<CustomComponent | null >,
  nextToken?: string | null,
};

export type CustomComponent = {
  __typename: "CustomComponent",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  org?: Organization | null,
  name: string,
  description: string,
  type: ComponentType,
  settings: string,
  updatedBy: CognitoUser,
  history?: ModelVailHistoryConnection | null,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  visibility?: Visibility | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationCustomComponentsId?: string | null,
  customComponentUpdatedById: string,
};

export enum ComponentType {
  authCode = "authCode",
  homeBase = "homeBase",
  dataIntake = "dataIntake",
  inlineSignup = "inlineSignup",
  introduction = "introduction",
  recipe = "recipe",
  semanticSearch = "semanticSearch",
  sentimentCheck = "sentimentCheck",
  taskTracker = "taskTracker",
  userGrouping = "userGrouping",
}


export enum ProcessingStatus {
  init = "init",
  complete = "complete",
  error = "error",
  processing = "processing",
}


export type ModelPromptSetConnection = {
  __typename: "ModelPromptSetConnection",
  items:  Array<PromptSet | null >,
  nextToken?: string | null,
};

export type PromptSet = {
  __typename: "PromptSet",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
  // 
  // assistant
  // user
  // assistant
  // user
  // ...
  // 
  // For completion prompt type, this is not used
  chat?:  Array<ChatPrompt > | null,
  maxTokens?: number | null,
  name: string,
  org?: Organization | null,
  prompt?: string | null,
  temperature?: number | null,
  type: PromptSetTypes,
  updatedBy: CognitoUser,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationPromptSetsId?: string | null,
  promptSetUpdatedById: string,
};

export type ChatPrompt = {
  __typename: "ChatPrompt",
  role: ChatPromptRole,
  response: string,
};

export type ModelNamespaceConnection = {
  __typename: "ModelNamespaceConnection",
  items:  Array<Namespace | null >,
  nextToken?: string | null,
};

export type Namespace = {
  __typename: "Namespace",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  org?: Organization | null,
  name: string,
  updatedBy: CognitoUser,
  documents?: ModelDocumentConnection | null,
  // This is the index name of the vector store DB
  indexName?: string | null,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationNamespacesId?: string | null,
  namespaceUpdatedById: string,
};

export type ModelDocumentConnection = {
  __typename: "ModelDocumentConnection",
  items:  Array<Document | null >,
  nextToken?: string | null,
};

export type Document = {
  __typename: "Document",
  updatedBy: CognitoUser,
  namespace?: Namespace | null,
  type?: DocTypes | null,
  source?: string | null,
  name: string,
  active: boolean,
  org: Organization,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  visibility?: Visibility | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  organizationDocumentsId?: string | null,
  namespaceDocumentsId?: string | null,
  documentUpdatedById: string,
};

export enum DocTypes {
  webpage = "webpage",
  pdf = "pdf",
}


export type CreationEG = {
  __typename: "CreationEG",
  createInRecipe: boolean,
  engagementGoal: EG,
};

export type EG = {
  __typename: "EG",
  identifier: string,
  status: EGStatus,
};

export enum EGStatus {
  ACTIVE = "ACTIVE",
  REQUESTED = "REQUESTED",
}


export type DeleteVailFAQInput = {
  id: string,
};

export type ModelVailFAQConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  and?: Array< ModelVailFAQConditionInput | null > | null,
  or?: Array< ModelVailFAQConditionInput | null > | null,
  not?: ModelVailFAQConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationFAQId?: ModelIDInput | null,
  vailFAQUpdatedById?: ModelIDInput | null,
};

export type DeleteVailHistoryInput = {
  id: string,
};

export type ModelVailHistoryConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  data?: ModelStringInput | null,
  name?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  lastDeployedLower?: ModelStringInput | null,
  lastDeployedUpper?: ModelStringInput | null,
  and?: Array< ModelVailHistoryConditionInput | null > | null,
  or?: Array< ModelVailHistoryConditionInput | null > | null,
  not?: ModelVailHistoryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  vailEngagementGoalHistoryId?: ModelIDInput | null,
  vailFAQHistoryId?: ModelIDInput | null,
  organizationHistoryId?: ModelIDInput | null,
  vailSolutionHistoryId?: ModelIDInput | null,
  recipeHistoryId?: ModelIDInput | null,
  customComponentHistoryId?: ModelIDInput | null,
  vailHistoryCreatedById?: ModelIDInput | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type ModelOrganizationConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  orgStatus?: ModelorgStatusInput | null,
  privacyPolicy?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  termsOfUse?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  solutionDeployed?: ModelStringInput | null,
  languagesSupported?: ModelStringInput | null,
  lastRelease?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationProdReleaseId?: ModelIDInput | null,
  organizationStagingReleaseId?: ModelIDInput | null,
  organizationUpdatedById?: ModelIDInput | null,
};

export type ModelorgStatusInput = {
  eq?: orgStatus | null,
  ne?: orgStatus | null,
};

export type DeleteEgCardInput = {
  id: string,
};

export type ModelEgCardConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  and?: Array< ModelEgCardConditionInput | null > | null,
  or?: Array< ModelEgCardConditionInput | null > | null,
  not?: ModelEgCardConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  vailEngagementGoalCardsId?: ModelIDInput | null,
  organizationEgCardsId?: ModelIDInput | null,
  vailSolutionEngagementGoalsId?: ModelIDInput | null,
  egCardVersionId?: ModelIDInput | null,
};

export type DeleteVailSolutionInput = {
  id: string,
};

export type ModelVailSolutionConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  name?: ModelStringInput | null,
  type?: ModelSolutionTypeInput | null,
  description?: ModelStringInput | null,
  entryPoint?: ModelStringInput | null,
  usesYML?: ModelBooleanInput | null,
  script?: ModelStringInput | null,
  lastDeployed?: ModelStringInput | null,
  visibility?: ModelVisibilityInput | null,
  and?: Array< ModelVailSolutionConditionInput | null > | null,
  or?: Array< ModelVailSolutionConditionInput | null > | null,
  not?: ModelVailSolutionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationSolutionId?: ModelIDInput | null,
  vailSolutionUpdatedById?: ModelIDInput | null,
};

export type ModelSolutionTypeInput = {
  eq?: SolutionType | null,
  ne?: SolutionType | null,
};

export type DeleteRecipeInput = {
  id: string,
};

export type ModelRecipeConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  scriptRecipe?: ModelStringInput | null,
  scriptCode?: ModelStringInput | null,
  and?: Array< ModelRecipeConditionInput | null > | null,
  or?: Array< ModelRecipeConditionInput | null > | null,
  not?: ModelRecipeConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationRecipeId?: ModelIDInput | null,
  recipeUpdatedById?: ModelIDInput | null,
};

export type CreateCognitoUserInput = {
  owner?: string | null,
  email: string,
  name: string,
  role: Permission,
  settings?: UserSettingsInput | null,
  demosShown?: Array< string > | null,
  id?: string | null,
};

export type UserSettingsInput = {
  fontSize?: number | null,
  theme?: Theme | null,
  recipeDelay?: number | null,
};

export type ModelCognitoUserConditionInput = {
  owner?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelPermissionInput | null,
  demosShown?: ModelStringInput | null,
  and?: Array< ModelCognitoUserConditionInput | null > | null,
  or?: Array< ModelCognitoUserConditionInput | null > | null,
  not?: ModelCognitoUserConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelPermissionInput = {
  eq?: Permission | null,
  ne?: Permission | null,
};

export type UpdateCognitoUserInput = {
  owner?: string | null,
  email?: string | null,
  name?: string | null,
  role?: Permission | null,
  settings?: UserSettingsInput | null,
  demosShown?: Array< string > | null,
  id: string,
};

export type DeleteCognitoUserInput = {
  id: string,
};

export type CreateVailReleaseInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  version: string,
  name: string,
  releaseNotes: string,
  lowerLinks?: Array< ReleaseLinkInput > | null,
  upperLinks?: Array< ReleaseLinkInput > | null,
  lowerReleaseBundle?: string | null,
  upperReleaseBundle?: string | null,
  publishedToProd?: boolean | null,
  id?: string | null,
  vailReleaseCreatedById: string,
  vailReleaseUpdatedById?: string | null,
  vailReleaseOrgId?: string | null,
};

export type ReleaseLinkInput = {
  name: string,
  url: string,
  componentLink: string,
};

export type ModelVailReleaseConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  version?: ModelStringInput | null,
  name?: ModelStringInput | null,
  releaseNotes?: ModelStringInput | null,
  lowerReleaseBundle?: ModelStringInput | null,
  upperReleaseBundle?: ModelStringInput | null,
  publishedToProd?: ModelBooleanInput | null,
  and?: Array< ModelVailReleaseConditionInput | null > | null,
  or?: Array< ModelVailReleaseConditionInput | null > | null,
  not?: ModelVailReleaseConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  vailReleaseCreatedById?: ModelIDInput | null,
  vailReleaseUpdatedById?: ModelIDInput | null,
  vailReleaseOrgId?: ModelIDInput | null,
};

export type DeleteVailReleaseInput = {
  id: string,
};

export type DeletePromptSetInput = {
  id: string,
};

export type ModelPromptSetConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  maxTokens?: ModelIntInput | null,
  name?: ModelStringInput | null,
  prompt?: ModelStringInput | null,
  temperature?: ModelFloatInput | null,
  type?: ModelPromptSetTypesInput | null,
  and?: Array< ModelPromptSetConditionInput | null > | null,
  or?: Array< ModelPromptSetConditionInput | null > | null,
  not?: ModelPromptSetConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationPromptSetsId?: ModelIDInput | null,
  promptSetUpdatedById?: ModelIDInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPromptSetTypesInput = {
  eq?: PromptSetTypes | null,
  ne?: PromptSetTypes | null,
};

export type DeleteDictionaryInput = {
  id: string,
};

export type ModelDictionaryConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  medicationName?: ModelStringInput | null,
  typeOfMedication?: ModelStringInput | null,
  customerProfile?: ModelStringInput | null,
  and?: Array< ModelDictionaryConditionInput | null > | null,
  or?: Array< ModelDictionaryConditionInput | null > | null,
  not?: ModelDictionaryConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Dictionary = {
  __typename: "Dictionary",
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  medicationName?: string | null,
  typeOfMedication?: string | null,
  customerProfile?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type CreateJobInput = {
  status?: JobStatus | null,
  name?: string | null,
  displayStatus?: string | null,
  id?: string | null,
  jobUserId?: string | null,
};

export enum JobStatus {
  pending = "pending",
  started = "started",
  processing = "processing",
  finished = "finished",
  failed = "failed",
}


export type ModelJobConditionInput = {
  status?: ModelJobStatusInput | null,
  name?: ModelStringInput | null,
  displayStatus?: ModelStringInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  jobUserId?: ModelIDInput | null,
};

export type ModelJobStatusInput = {
  eq?: JobStatus | null,
  ne?: JobStatus | null,
};

export type Job = {
  __typename: "Job",
  user?: CognitoUser | null,
  status?: JobStatus | null,
  name?: string | null,
  displayStatus?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  jobUserId?: string | null,
};

export type UpdateJobInput = {
  status?: JobStatus | null,
  name?: string | null,
  displayStatus?: string | null,
  id: string,
  jobUserId?: string | null,
};

export type DeleteJobInput = {
  id: string,
};

export type DeleteNamespaceInput = {
  id: string,
};

export type ModelNamespaceConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  indexName?: ModelStringInput | null,
  status?: ModelProcessingStatusInput | null,
  displayStatus?: ModelStringInput | null,
  and?: Array< ModelNamespaceConditionInput | null > | null,
  or?: Array< ModelNamespaceConditionInput | null > | null,
  not?: ModelNamespaceConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationNamespacesId?: ModelIDInput | null,
  namespaceUpdatedById?: ModelIDInput | null,
};

export type ModelProcessingStatusInput = {
  eq?: ProcessingStatus | null,
  ne?: ProcessingStatus | null,
};

export type DeleteCustomComponentInput = {
  id: string,
};

export type ModelCustomComponentConditionInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelComponentTypeInput | null,
  settings?: ModelStringInput | null,
  status?: ModelProcessingStatusInput | null,
  displayStatus?: ModelStringInput | null,
  visibility?: ModelVisibilityInput | null,
  and?: Array< ModelCustomComponentConditionInput | null > | null,
  or?: Array< ModelCustomComponentConditionInput | null > | null,
  not?: ModelCustomComponentConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationCustomComponentsId?: ModelIDInput | null,
  customComponentUpdatedById?: ModelIDInput | null,
};

export type ModelComponentTypeInput = {
  eq?: ComponentType | null,
  ne?: ComponentType | null,
};

export type CreateReleaseHistoriesInput = {
  id?: string | null,
  vailHistoryID: string,
  vailReleaseID: string,
};

export type ModelReleaseHistoriesConditionInput = {
  vailHistoryID?: ModelIDInput | null,
  vailReleaseID?: ModelIDInput | null,
  and?: Array< ModelReleaseHistoriesConditionInput | null > | null,
  or?: Array< ModelReleaseHistoriesConditionInput | null > | null,
  not?: ModelReleaseHistoriesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DeleteReleaseHistoriesInput = {
  id: string,
};

export type CreateVailEngagementGoalInput = {
  admins?: string | null,
  editors?: string | null,
  readers?: string | null,
  name: string,
  description: string,
  priority: Priority,
  script?: Array< ReleaseBundleInput > | null,
  creation?: CreationEGInput | null,
  archived?: Visibility | null,
  spec: string,
  notification?: string | null,
  initialConversation?: boolean | null,
  id?: string | null,
  organizationEngagementGoalsId?: string | null,
  vailEngagementGoalUpdatedById?: string | null,
};

export type ReleaseBundleInput = {
  name: string,
  version: string,
  type: string,
  extra?: string | null,
};

export type CreationEGInput = {
  createInRecipe: boolean,
  engagementGoal: EGInput,
};

export type EGInput = {
  identifier: string,
  status: EGStatus,
};

export type UpdateVailEngagementGoalInput = {
  admins?: string | null,
  editors?: string | null,
  readers?: string | null,
  name?: string | null,
  description?: string | null,
  priority?: Priority | null,
  script?: Array< ReleaseBundleInput > | null,
  creation?: CreationEGInput | null,
  archived?: Visibility | null,
  spec?: string | null,
  notification?: string | null,
  initialConversation?: boolean | null,
  id: string,
  organizationEngagementGoalsId?: string | null,
  vailEngagementGoalUpdatedById?: string | null,
};

export type CreateVailFAQInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  autoSuggestConfig: AutoSuggestConfigInput,
  menuConfig: FAQMenuConfigInput,
  name: string,
  questions?: Array< FAQQuestionInput > | null,
  active: boolean,
  id?: string | null,
  organizationFAQId?: string | null,
  vailFAQUpdatedById?: string | null,
};

export type AutoSuggestConfigInput = {
  ignoreWords: Array< string >,
  minimumCharacters: number,
  preSearchPrompt: string,
  questionNotFoundButton: string,
  returnToMainMenuButton: string,
  searchBoxPlaceholder: string,
};

export type FAQMenuConfigInput = {
  answerCompleteButton: Array< string >,
  defaultQuestions: Array< string >,
  disclaimerText: string,
  displayRandomQuestionsInMenu: boolean,
  exitFaqComponentButton: string,
  startSearchButton: string,
  questionNotFoundStep: string,
  faqCompleteNextStep: string,
  menuPrompt: Array< string >,
  persistentQuestion: Array< string >,
};

export type FAQQuestionInput = {
  answer?: Array< FAQAnswerInput > | null,
  disclaimer: boolean,
  key: string,
  id?: string | null,
  keywords: Array< string >,
  question: string,
  suggestedQuestions?: Array< string > | null,
};

export type FAQAnswerInput = {
  type: FAQAnswerType,
  value: string,
};

export type UpdateVailFAQInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  autoSuggestConfig?: AutoSuggestConfigInput | null,
  menuConfig?: FAQMenuConfigInput | null,
  name?: string | null,
  questions?: Array< FAQQuestionInput > | null,
  active?: boolean | null,
  id: string,
  organizationFAQId?: string | null,
  vailFAQUpdatedById?: string | null,
};

export type CreateVailHistoryInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  data: string,
  name: string,
  notes?: string | null,
  platformKeys?: PlatformKeysInput | null,
  lastDeployedLower?: string | null,
  lastDeployedUpper?: string | null,
  id?: string | null,
  vailEngagementGoalHistoryId?: string | null,
  vailFAQHistoryId?: string | null,
  organizationHistoryId?: string | null,
  vailSolutionHistoryId?: string | null,
  recipeHistoryId?: string | null,
  customComponentHistoryId?: string | null,
  vailHistoryCreatedById?: string | null,
};

export type PlatformKeysInput = {
  lowerEnv?: string | null,
  middleEnv?: string | null,
  upperEnv?: string | null,
};

export type UpdateVailHistoryInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  data?: string | null,
  name?: string | null,
  notes?: string | null,
  platformKeys?: PlatformKeysInput | null,
  lastDeployedLower?: string | null,
  lastDeployedUpper?: string | null,
  id: string,
  vailEngagementGoalHistoryId?: string | null,
  vailFAQHistoryId?: string | null,
  organizationHistoryId?: string | null,
  vailSolutionHistoryId?: string | null,
  recipeHistoryId?: string | null,
  customComponentHistoryId?: string | null,
  vailHistoryCreatedById?: string | null,
};

export type CreateOrganizationInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  name: string,
  orgStatus?: orgStatus | null,
  privacyPolicy?: string | null,
  slug: string,
  tags: Array< string >,
  termsOfUse?: string | null,
  phoneNumber?: string | null,
  solutionDeployed?: string | null,
  languagesSupported?: Array< string > | null,
  lastRelease?: string | null,
  id?: string | null,
  organizationProdReleaseId?: string | null,
  organizationStagingReleaseId?: string | null,
  organizationUpdatedById?: string | null,
};

export type UpdateOrganizationInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  name?: string | null,
  orgStatus?: orgStatus | null,
  privacyPolicy?: string | null,
  slug?: string | null,
  tags?: Array< string > | null,
  termsOfUse?: string | null,
  phoneNumber?: string | null,
  solutionDeployed?: string | null,
  languagesSupported?: Array< string > | null,
  lastRelease?: string | null,
  id: string,
  organizationProdReleaseId?: string | null,
  organizationStagingReleaseId?: string | null,
  organizationUpdatedById?: string | null,
};

export type CreateEgCardInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  id?: string | null,
  vailEngagementGoalCardsId?: string | null,
  organizationEgCardsId?: string | null,
  vailSolutionEngagementGoalsId?: string | null,
  egCardVersionId: string,
};

export type UpdateEgCardInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  id: string,
  vailEngagementGoalCardsId?: string | null,
  organizationEgCardsId?: string | null,
  vailSolutionEngagementGoalsId?: string | null,
  egCardVersionId?: string | null,
};

export type CreateVailSolutionInput = {
  editors?: string | null,
  readers?: string | null,
  name: string,
  type: SolutionType,
  description?: string | null,
  entryPoint: string,
  components?: Array< ReleaseBundleInput > | null,
  usesYML?: boolean | null,
  script?: string | null,
  lastDeployed?: string | null,
  visibility?: Visibility | null,
  id?: string | null,
  organizationSolutionId?: string | null,
  vailSolutionUpdatedById?: string | null,
};

export type UpdateVailSolutionInput = {
  editors?: string | null,
  readers?: string | null,
  name?: string | null,
  type?: SolutionType | null,
  description?: string | null,
  entryPoint?: string | null,
  components?: Array< ReleaseBundleInput > | null,
  usesYML?: boolean | null,
  script?: string | null,
  lastDeployed?: string | null,
  visibility?: Visibility | null,
  id: string,
  organizationSolutionId?: string | null,
  vailSolutionUpdatedById?: string | null,
};

export type CreateRecipeInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  scriptRecipe: string,
  settings: RecipeSettingsInput,
  scriptCode?: string | null,
  id?: string | null,
  organizationRecipeId?: string | null,
  recipeUpdatedById?: string | null,
};

export type RecipeSettingsInput = {
  description: string,
  name: string,
};

export type UpdateRecipeInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  scriptRecipe?: string | null,
  settings?: RecipeSettingsInput | null,
  scriptCode?: string | null,
  id: string,
  organizationRecipeId?: string | null,
  recipeUpdatedById?: string | null,
};

export type UpdateVailReleaseInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  version?: string | null,
  name?: string | null,
  releaseNotes?: string | null,
  lowerLinks?: Array< ReleaseLinkInput > | null,
  upperLinks?: Array< ReleaseLinkInput > | null,
  lowerReleaseBundle?: string | null,
  upperReleaseBundle?: string | null,
  publishedToProd?: boolean | null,
  id: string,
  vailReleaseCreatedById?: string | null,
  vailReleaseUpdatedById?: string | null,
  vailReleaseOrgId?: string | null,
};

export type CreatePromptSetInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  chat?: Array< ChatPromptInput > | null,
  maxTokens?: number | null,
  name: string,
  prompt?: string | null,
  temperature?: number | null,
  type: PromptSetTypes,
  id?: string | null,
  organizationPromptSetsId?: string | null,
  promptSetUpdatedById: string,
};

export type UpdatePromptSetInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  chat?: Array< ChatPromptInput > | null,
  maxTokens?: number | null,
  name?: string | null,
  prompt?: string | null,
  temperature?: number | null,
  type?: PromptSetTypes | null,
  id: string,
  organizationPromptSetsId?: string | null,
  promptSetUpdatedById?: string | null,
};

export type CreateDictionaryInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  medicationName?: string | null,
  typeOfMedication?: string | null,
  customerProfile?: string | null,
  id?: string | null,
};

export type UpdateDictionaryInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  medicationName?: string | null,
  typeOfMedication?: string | null,
  customerProfile?: string | null,
  id: string,
};

export type CreateNamespaceInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  name: string,
  indexName?: string | null,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  id?: string | null,
  organizationNamespacesId?: string | null,
  namespaceUpdatedById: string,
};

export type UpdateNamespaceInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  name?: string | null,
  indexName?: string | null,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  id: string,
  organizationNamespacesId?: string | null,
  namespaceUpdatedById?: string | null,
};

export type CreateDocumentInput = {
  type?: DocTypes | null,
  source?: string | null,
  name: string,
  active: boolean,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  visibility?: Visibility | null,
  id?: string | null,
  organizationDocumentsId?: string | null,
  namespaceDocumentsId?: string | null,
  documentUpdatedById: string,
};

export type ModelDocumentConditionInput = {
  type?: ModelDocTypesInput | null,
  source?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  status?: ModelProcessingStatusInput | null,
  displayStatus?: ModelStringInput | null,
  visibility?: ModelVisibilityInput | null,
  and?: Array< ModelDocumentConditionInput | null > | null,
  or?: Array< ModelDocumentConditionInput | null > | null,
  not?: ModelDocumentConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  organizationDocumentsId?: ModelIDInput | null,
  namespaceDocumentsId?: ModelIDInput | null,
  documentUpdatedById?: ModelIDInput | null,
};

export type ModelDocTypesInput = {
  eq?: DocTypes | null,
  ne?: DocTypes | null,
};

export type UpdateDocumentInput = {
  type?: DocTypes | null,
  source?: string | null,
  name?: string | null,
  active?: boolean | null,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  visibility?: Visibility | null,
  id: string,
  organizationDocumentsId?: string | null,
  namespaceDocumentsId?: string | null,
  documentUpdatedById?: string | null,
};

export type DeleteDocumentInput = {
  id: string,
};

export type CreateCustomComponentInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  name: string,
  description: string,
  type: ComponentType,
  settings: string,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  visibility?: Visibility | null,
  id?: string | null,
  organizationCustomComponentsId?: string | null,
  customComponentUpdatedById: string,
};

export type UpdateCustomComponentInput = {
  editors?: string | null,
  readers?: string | null,
  admins?: string | null,
  name?: string | null,
  description?: string | null,
  type?: ComponentType | null,
  settings?: string | null,
  status?: ProcessingStatus | null,
  displayStatus?: string | null,
  visibility?: Visibility | null,
  id: string,
  organizationCustomComponentsId?: string | null,
  customComponentUpdatedById?: string | null,
};

export type CreateWorkshopCacheInput = {
  hash: string,
  env: string,
  workshopKey: string,
  id?: string | null,
};

export type ModelWorkshopCacheConditionInput = {
  hash?: ModelStringInput | null,
  env?: ModelStringInput | null,
  workshopKey?: ModelStringInput | null,
  and?: Array< ModelWorkshopCacheConditionInput | null > | null,
  or?: Array< ModelWorkshopCacheConditionInput | null > | null,
  not?: ModelWorkshopCacheConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type WorkshopCache = {
  __typename: "WorkshopCache",
  hash: string,
  env: string,
  workshopKey: string,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateWorkshopCacheInput = {
  hash?: string | null,
  env?: string | null,
  workshopKey?: string | null,
  id: string,
};

export type DeleteWorkshopCacheInput = {
  id: string,
};

export type UpdateReleaseHistoriesInput = {
  id: string,
  vailHistoryID?: string | null,
  vailReleaseID?: string | null,
};

export type Validation = {
  __typename: "Validation",
  valid: boolean,
  error?: string | null,
};

export type ModelCognitoUserFilterInput = {
  owner?: ModelStringInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  role?: ModelPermissionInput | null,
  demosShown?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCognitoUserFilterInput | null > | null,
  or?: Array< ModelCognitoUserFilterInput | null > | null,
  not?: ModelCognitoUserFilterInput | null,
};

export type ModelCognitoUserConnection = {
  __typename: "ModelCognitoUserConnection",
  items:  Array<CognitoUser | null >,
  nextToken?: string | null,
};

export type ModelJobFilterInput = {
  status?: ModelJobStatusInput | null,
  name?: ModelStringInput | null,
  displayStatus?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
  jobUserId?: ModelIDInput | null,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type ModelVailEngagementGoalFilterInput = {
  admins?: ModelStringInput | null,
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  priority?: ModelPriorityInput | null,
  archived?: ModelVisibilityInput | null,
  spec?: ModelStringInput | null,
  notification?: ModelStringInput | null,
  initialConversation?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVailEngagementGoalFilterInput | null > | null,
  or?: Array< ModelVailEngagementGoalFilterInput | null > | null,
  not?: ModelVailEngagementGoalFilterInput | null,
  organizationEngagementGoalsId?: ModelIDInput | null,
  vailEngagementGoalUpdatedById?: ModelIDInput | null,
};

export type ModelVailFAQFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVailFAQFilterInput | null > | null,
  or?: Array< ModelVailFAQFilterInput | null > | null,
  not?: ModelVailFAQFilterInput | null,
  organizationFAQId?: ModelIDInput | null,
  vailFAQUpdatedById?: ModelIDInput | null,
};

export type ModelVailHistoryFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  data?: ModelStringInput | null,
  name?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  lastDeployedLower?: ModelStringInput | null,
  lastDeployedUpper?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVailHistoryFilterInput | null > | null,
  or?: Array< ModelVailHistoryFilterInput | null > | null,
  not?: ModelVailHistoryFilterInput | null,
  vailEngagementGoalHistoryId?: ModelIDInput | null,
  vailFAQHistoryId?: ModelIDInput | null,
  organizationHistoryId?: ModelIDInput | null,
  vailSolutionHistoryId?: ModelIDInput | null,
  recipeHistoryId?: ModelIDInput | null,
  customComponentHistoryId?: ModelIDInput | null,
  vailHistoryCreatedById?: ModelIDInput | null,
};

export type ModelOrganizationFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  orgStatus?: ModelorgStatusInput | null,
  privacyPolicy?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  termsOfUse?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  solutionDeployed?: ModelStringInput | null,
  languagesSupported?: ModelStringInput | null,
  lastRelease?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
  organizationProdReleaseId?: ModelIDInput | null,
  organizationStagingReleaseId?: ModelIDInput | null,
  organizationUpdatedById?: ModelIDInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelEgCardFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEgCardFilterInput | null > | null,
  or?: Array< ModelEgCardFilterInput | null > | null,
  not?: ModelEgCardFilterInput | null,
  vailEngagementGoalCardsId?: ModelIDInput | null,
  organizationEgCardsId?: ModelIDInput | null,
  vailSolutionEngagementGoalsId?: ModelIDInput | null,
  egCardVersionId?: ModelIDInput | null,
};

export type ModelVailSolutionFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  name?: ModelStringInput | null,
  type?: ModelSolutionTypeInput | null,
  description?: ModelStringInput | null,
  entryPoint?: ModelStringInput | null,
  usesYML?: ModelBooleanInput | null,
  script?: ModelStringInput | null,
  lastDeployed?: ModelStringInput | null,
  visibility?: ModelVisibilityInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVailSolutionFilterInput | null > | null,
  or?: Array< ModelVailSolutionFilterInput | null > | null,
  not?: ModelVailSolutionFilterInput | null,
  organizationSolutionId?: ModelIDInput | null,
  vailSolutionUpdatedById?: ModelIDInput | null,
};

export type ModelRecipeFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  scriptRecipe?: ModelStringInput | null,
  scriptCode?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRecipeFilterInput | null > | null,
  or?: Array< ModelRecipeFilterInput | null > | null,
  not?: ModelRecipeFilterInput | null,
  organizationRecipeId?: ModelIDInput | null,
  recipeUpdatedById?: ModelIDInput | null,
};

export type ModelVailReleaseFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  version?: ModelStringInput | null,
  name?: ModelStringInput | null,
  releaseNotes?: ModelStringInput | null,
  lowerReleaseBundle?: ModelStringInput | null,
  upperReleaseBundle?: ModelStringInput | null,
  publishedToProd?: ModelBooleanInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVailReleaseFilterInput | null > | null,
  or?: Array< ModelVailReleaseFilterInput | null > | null,
  not?: ModelVailReleaseFilterInput | null,
  vailReleaseCreatedById?: ModelIDInput | null,
  vailReleaseUpdatedById?: ModelIDInput | null,
  vailReleaseOrgId?: ModelIDInput | null,
};

export type ModelVailReleaseConnection = {
  __typename: "ModelVailReleaseConnection",
  items:  Array<VailRelease | null >,
  nextToken?: string | null,
};

export type ModelPromptSetFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  maxTokens?: ModelIntInput | null,
  name?: ModelStringInput | null,
  prompt?: ModelStringInput | null,
  temperature?: ModelFloatInput | null,
  type?: ModelPromptSetTypesInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPromptSetFilterInput | null > | null,
  or?: Array< ModelPromptSetFilterInput | null > | null,
  not?: ModelPromptSetFilterInput | null,
  organizationPromptSetsId?: ModelIDInput | null,
  promptSetUpdatedById?: ModelIDInput | null,
};

export type ModelDictionaryFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  medicationName?: ModelStringInput | null,
  typeOfMedication?: ModelStringInput | null,
  customerProfile?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDictionaryFilterInput | null > | null,
  or?: Array< ModelDictionaryFilterInput | null > | null,
  not?: ModelDictionaryFilterInput | null,
};

export type ModelDictionaryConnection = {
  __typename: "ModelDictionaryConnection",
  items:  Array<Dictionary | null >,
  nextToken?: string | null,
};

export type ModelNamespaceFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  indexName?: ModelStringInput | null,
  status?: ModelProcessingStatusInput | null,
  displayStatus?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNamespaceFilterInput | null > | null,
  or?: Array< ModelNamespaceFilterInput | null > | null,
  not?: ModelNamespaceFilterInput | null,
  organizationNamespacesId?: ModelIDInput | null,
  namespaceUpdatedById?: ModelIDInput | null,
};

export type ModelDocumentFilterInput = {
  type?: ModelDocTypesInput | null,
  source?: ModelStringInput | null,
  name?: ModelStringInput | null,
  active?: ModelBooleanInput | null,
  status?: ModelProcessingStatusInput | null,
  displayStatus?: ModelStringInput | null,
  visibility?: ModelVisibilityInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDocumentFilterInput | null > | null,
  or?: Array< ModelDocumentFilterInput | null > | null,
  not?: ModelDocumentFilterInput | null,
  organizationDocumentsId?: ModelIDInput | null,
  namespaceDocumentsId?: ModelIDInput | null,
  documentUpdatedById?: ModelIDInput | null,
};

export type ModelCustomComponentFilterInput = {
  editors?: ModelStringInput | null,
  readers?: ModelStringInput | null,
  admins?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelComponentTypeInput | null,
  settings?: ModelStringInput | null,
  status?: ModelProcessingStatusInput | null,
  displayStatus?: ModelStringInput | null,
  visibility?: ModelVisibilityInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCustomComponentFilterInput | null > | null,
  or?: Array< ModelCustomComponentFilterInput | null > | null,
  not?: ModelCustomComponentFilterInput | null,
  organizationCustomComponentsId?: ModelIDInput | null,
  customComponentUpdatedById?: ModelIDInput | null,
};

export type ModelWorkshopCacheFilterInput = {
  hash?: ModelStringInput | null,
  env?: ModelStringInput | null,
  workshopKey?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWorkshopCacheFilterInput | null > | null,
  or?: Array< ModelWorkshopCacheFilterInput | null > | null,
  not?: ModelWorkshopCacheFilterInput | null,
};

export type ModelWorkshopCacheConnection = {
  __typename: "ModelWorkshopCacheConnection",
  items:  Array<WorkshopCache | null >,
  nextToken?: string | null,
};

export type ModelReleaseHistoriesFilterInput = {
  id?: ModelIDInput | null,
  vailHistoryID?: ModelIDInput | null,
  vailReleaseID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReleaseHistoriesFilterInput | null > | null,
  or?: Array< ModelReleaseHistoriesFilterInput | null > | null,
  not?: ModelReleaseHistoriesFilterInput | null,
};

export type ModelSubscriptionCognitoUserFilterInput = {
  email?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  role?: ModelSubscriptionStringInput | null,
  demosShown?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCognitoUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionCognitoUserFilterInput | null > | null,
  owner?: ModelStringInput | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionJobFilterInput = {
  status?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  displayStatus?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionJobFilterInput | null > | null,
  or?: Array< ModelSubscriptionJobFilterInput | null > | null,
  jobUserId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionVailEngagementGoalFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  priority?: ModelSubscriptionStringInput | null,
  archived?: ModelSubscriptionStringInput | null,
  spec?: ModelSubscriptionStringInput | null,
  notification?: ModelSubscriptionStringInput | null,
  initialConversation?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVailEngagementGoalFilterInput | null > | null,
  or?: Array< ModelSubscriptionVailEngagementGoalFilterInput | null > | null,
  vailEngagementGoalHistoryId?: ModelSubscriptionIDInput | null,
  vailEngagementGoalCardsId?: ModelSubscriptionIDInput | null,
  vailEngagementGoalUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionVailFAQFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVailFAQFilterInput | null > | null,
  or?: Array< ModelSubscriptionVailFAQFilterInput | null > | null,
  vailFAQHistoryId?: ModelSubscriptionIDInput | null,
  vailFAQUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionVailHistoryFilterInput = {
  data?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  notes?: ModelSubscriptionStringInput | null,
  lastDeployedLower?: ModelSubscriptionStringInput | null,
  lastDeployedUpper?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVailHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionVailHistoryFilterInput | null > | null,
  vailHistoryCreatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionOrganizationFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  orgStatus?: ModelSubscriptionStringInput | null,
  privacyPolicy?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  termsOfUse?: ModelSubscriptionStringInput | null,
  phoneNumber?: ModelSubscriptionStringInput | null,
  solutionDeployed?: ModelSubscriptionStringInput | null,
  languagesSupported?: ModelSubscriptionStringInput | null,
  lastRelease?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrganizationFilterInput | null > | null,
  organizationSolutionId?: ModelSubscriptionIDInput | null,
  organizationFAQId?: ModelSubscriptionIDInput | null,
  organizationEngagementGoalsId?: ModelSubscriptionIDInput | null,
  organizationHistoryId?: ModelSubscriptionIDInput | null,
  organizationRecipeId?: ModelSubscriptionIDInput | null,
  organizationEgCardsId?: ModelSubscriptionIDInput | null,
  organizationCustomComponentsId?: ModelSubscriptionIDInput | null,
  organizationPromptSetsId?: ModelSubscriptionIDInput | null,
  organizationNamespacesId?: ModelSubscriptionIDInput | null,
  organizationDocumentsId?: ModelSubscriptionIDInput | null,
  organizationProdReleaseId?: ModelSubscriptionIDInput | null,
  organizationStagingReleaseId?: ModelSubscriptionIDInput | null,
  organizationUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionEgCardFilterInput = {
  admins?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEgCardFilterInput | null > | null,
  or?: Array< ModelSubscriptionEgCardFilterInput | null > | null,
  egCardVersionId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionVailSolutionFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  entryPoint?: ModelSubscriptionStringInput | null,
  usesYML?: ModelSubscriptionBooleanInput | null,
  script?: ModelSubscriptionStringInput | null,
  lastDeployed?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVailSolutionFilterInput | null > | null,
  or?: Array< ModelSubscriptionVailSolutionFilterInput | null > | null,
  vailSolutionHistoryId?: ModelSubscriptionIDInput | null,
  vailSolutionEngagementGoalsId?: ModelSubscriptionIDInput | null,
  vailSolutionUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionRecipeFilterInput = {
  scriptRecipe?: ModelSubscriptionStringInput | null,
  scriptCode?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRecipeFilterInput | null > | null,
  or?: Array< ModelSubscriptionRecipeFilterInput | null > | null,
  recipeHistoryId?: ModelSubscriptionIDInput | null,
  recipeUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionVailReleaseFilterInput = {
  version?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  releaseNotes?: ModelSubscriptionStringInput | null,
  lowerReleaseBundle?: ModelSubscriptionStringInput | null,
  upperReleaseBundle?: ModelSubscriptionStringInput | null,
  publishedToProd?: ModelSubscriptionBooleanInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVailReleaseFilterInput | null > | null,
  or?: Array< ModelSubscriptionVailReleaseFilterInput | null > | null,
  vailReleaseCreatedById?: ModelSubscriptionIDInput | null,
  vailReleaseUpdatedById?: ModelSubscriptionIDInput | null,
  vailReleaseOrgId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionPromptSetFilterInput = {
  maxTokens?: ModelSubscriptionIntInput | null,
  name?: ModelSubscriptionStringInput | null,
  prompt?: ModelSubscriptionStringInput | null,
  temperature?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPromptSetFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromptSetFilterInput | null > | null,
  promptSetUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionDictionaryFilterInput = {
  medicationName?: ModelSubscriptionStringInput | null,
  typeOfMedication?: ModelSubscriptionStringInput | null,
  customerProfile?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDictionaryFilterInput | null > | null,
  or?: Array< ModelSubscriptionDictionaryFilterInput | null > | null,
};

export type ModelSubscriptionNamespaceFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  indexName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  displayStatus?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNamespaceFilterInput | null > | null,
  or?: Array< ModelSubscriptionNamespaceFilterInput | null > | null,
  namespaceDocumentsId?: ModelSubscriptionIDInput | null,
  namespaceUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionDocumentFilterInput = {
  type?: ModelSubscriptionStringInput | null,
  source?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  active?: ModelSubscriptionBooleanInput | null,
  status?: ModelSubscriptionStringInput | null,
  displayStatus?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDocumentFilterInput | null > | null,
  documentUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionCustomComponentFilterInput = {
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  settings?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  displayStatus?: ModelSubscriptionStringInput | null,
  visibility?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCustomComponentFilterInput | null > | null,
  or?: Array< ModelSubscriptionCustomComponentFilterInput | null > | null,
  customComponentHistoryId?: ModelSubscriptionIDInput | null,
  customComponentUpdatedById?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionWorkshopCacheFilterInput = {
  hash?: ModelSubscriptionStringInput | null,
  env?: ModelSubscriptionStringInput | null,
  workshopKey?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkshopCacheFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkshopCacheFilterInput | null > | null,
};

export type ModelSubscriptionReleaseHistoriesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  vailHistoryID?: ModelSubscriptionIDInput | null,
  vailReleaseID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReleaseHistoriesFilterInput | null > | null,
  or?: Array< ModelSubscriptionReleaseHistoriesFilterInput | null > | null,
};

export type PublishOrganizationMutationVariables = {
  organizationID: string,
  lowerEnv: string,
  upperEnv: string,
  releaseID: string,
  solutionMetadata?: Array< solutionMetadata > | null,
};

export type PublishOrganizationMutation = {
  publishOrganization?: string | null,
};

export type PromoteReleaseBundleMutationVariables = {
  releaseBundle: string,
  env?: string | null,
};

export type PromoteReleaseBundleMutation = {
  promoteReleaseBundle?: string | null,
};

export type SendBasicSolutionMutationVariables = {
  solution: string,
  env?: string | null,
};

export type SendBasicSolutionMutation = {
  sendBasicSolution?: string | null,
};

export type SendHistoryMutationVariables = {
  history: string,
  env?: string | null,
};

export type SendHistoryMutation = {
  sendHistory?: string | null,
};

export type SendAdvancedSolutionMutationVariables = {
  solution: string,
  env?: string | null,
  pathToSave: Array< string >,
  history: string,
};

export type SendAdvancedSolutionMutation = {
  sendAdvancedSolution?: string | null,
};

export type GenerateEmbeddingsMutationVariables = {
  customComponentID: string,
  version: string,
};

export type GenerateEmbeddingsMutation = {
  generateEmbeddings: string,
};

export type UpsertDocumentMutationVariables = {
  documentID: string,
  documentSetSlug: string,
};

export type UpsertDocumentMutation = {
  upsertDocument?: string | null,
};

export type RemoveDocumentMutationVariables = {
  documentID: string,
  documentSetSlug: string,
};

export type RemoveDocumentMutation = {
  removeDocument?: string | null,
};

export type RemoveNamespaceMutationVariables = {
  namespaceID: string,
  documentSetSlug: string,
};

export type RemoveNamespaceMutation = {
  removeNamespace?: string | null,
};

export type GenerateAnswersMutationVariables = {
  keys: Array< string >,
  customComponentID: string,
  namespaceID: string,
  documentSetSlug: string,
  promptSetType: PromptSetTypes,
  prompt: string,
  chat: Array< ChatPromptInput >,
};

export type GenerateAnswersMutation = {
  generateAnswers?: string | null,
};

export type TranslateCustomComponentMutationVariables = {
  from: string,
  to: Array< string >,
  data: string,
};

export type TranslateCustomComponentMutation = {
  translateCustomComponent?: string | null,
};

export type DeleteVailEngagementGoalMutationVariables = {
  input: DeleteVailEngagementGoalInput,
  condition?: ModelVailEngagementGoalConditionInput | null,
};

export type DeleteVailEngagementGoalMutation = {
  deleteVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type DeleteVailFAQMutationVariables = {
  input: DeleteVailFAQInput,
  condition?: ModelVailFAQConditionInput | null,
};

export type DeleteVailFAQMutation = {
  deleteVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type DeleteVailHistoryMutationVariables = {
  input: DeleteVailHistoryInput,
  condition?: ModelVailHistoryConditionInput | null,
};

export type DeleteVailHistoryMutation = {
  deleteVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type DeleteEgCardMutationVariables = {
  input: DeleteEgCardInput,
  condition?: ModelEgCardConditionInput | null,
};

export type DeleteEgCardMutation = {
  deleteEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type DeleteVailSolutionMutationVariables = {
  input: DeleteVailSolutionInput,
  condition?: ModelVailSolutionConditionInput | null,
};

export type DeleteVailSolutionMutation = {
  deleteVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type DeleteRecipeMutationVariables = {
  input: DeleteRecipeInput,
  condition?: ModelRecipeConditionInput | null,
};

export type DeleteRecipeMutation = {
  deleteRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type CreateCognitoUserMutationVariables = {
  input: CreateCognitoUserInput,
  condition?: ModelCognitoUserConditionInput | null,
};

export type CreateCognitoUserMutation = {
  createCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCognitoUserMutationVariables = {
  input: UpdateCognitoUserInput,
  condition?: ModelCognitoUserConditionInput | null,
};

export type UpdateCognitoUserMutation = {
  updateCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCognitoUserMutationVariables = {
  input: DeleteCognitoUserInput,
  condition?: ModelCognitoUserConditionInput | null,
};

export type DeleteCognitoUserMutation = {
  deleteCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVailReleaseMutationVariables = {
  input: CreateVailReleaseInput,
  condition?: ModelVailReleaseConditionInput | null,
};

export type CreateVailReleaseMutation = {
  createVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type DeleteVailReleaseMutationVariables = {
  input: DeleteVailReleaseInput,
  condition?: ModelVailReleaseConditionInput | null,
};

export type DeleteVailReleaseMutation = {
  deleteVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type DeletePromptSetMutationVariables = {
  input: DeletePromptSetInput,
  condition?: ModelPromptSetConditionInput | null,
};

export type DeletePromptSetMutation = {
  deletePromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type DeleteDictionaryMutationVariables = {
  input: DeleteDictionaryInput,
  condition?: ModelDictionaryConditionInput | null,
};

export type DeleteDictionaryMutation = {
  deleteDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type DeleteNamespaceMutationVariables = {
  input: DeleteNamespaceInput,
  condition?: ModelNamespaceConditionInput | null,
};

export type DeleteNamespaceMutation = {
  deleteNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type DeleteCustomComponentMutationVariables = {
  input: DeleteCustomComponentInput,
  condition?: ModelCustomComponentConditionInput | null,
};

export type DeleteCustomComponentMutation = {
  deleteCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type CreateReleaseHistoriesMutationVariables = {
  input: CreateReleaseHistoriesInput,
  condition?: ModelReleaseHistoriesConditionInput | null,
};

export type CreateReleaseHistoriesMutation = {
  createReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReleaseHistoriesMutationVariables = {
  input: DeleteReleaseHistoriesInput,
  condition?: ModelReleaseHistoriesConditionInput | null,
};

export type DeleteReleaseHistoriesMutation = {
  deleteReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVailEngagementGoalMutationVariables = {
  input: CreateVailEngagementGoalInput,
  condition?: ModelVailEngagementGoalConditionInput | null,
};

export type CreateVailEngagementGoalMutation = {
  createVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type UpdateVailEngagementGoalMutationVariables = {
  input: UpdateVailEngagementGoalInput,
  condition?: ModelVailEngagementGoalConditionInput | null,
};

export type UpdateVailEngagementGoalMutation = {
  updateVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type CreateVailFAQMutationVariables = {
  input: CreateVailFAQInput,
  condition?: ModelVailFAQConditionInput | null,
};

export type CreateVailFAQMutation = {
  createVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type UpdateVailFAQMutationVariables = {
  input: UpdateVailFAQInput,
  condition?: ModelVailFAQConditionInput | null,
};

export type UpdateVailFAQMutation = {
  updateVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type CreateVailHistoryMutationVariables = {
  input: CreateVailHistoryInput,
  condition?: ModelVailHistoryConditionInput | null,
};

export type CreateVailHistoryMutation = {
  createVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type UpdateVailHistoryMutationVariables = {
  input: UpdateVailHistoryInput,
  condition?: ModelVailHistoryConditionInput | null,
};

export type UpdateVailHistoryMutation = {
  updateVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type CreateEgCardMutationVariables = {
  input: CreateEgCardInput,
  condition?: ModelEgCardConditionInput | null,
};

export type CreateEgCardMutation = {
  createEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type UpdateEgCardMutationVariables = {
  input: UpdateEgCardInput,
  condition?: ModelEgCardConditionInput | null,
};

export type UpdateEgCardMutation = {
  updateEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type CreateVailSolutionMutationVariables = {
  input: CreateVailSolutionInput,
  condition?: ModelVailSolutionConditionInput | null,
};

export type CreateVailSolutionMutation = {
  createVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type UpdateVailSolutionMutationVariables = {
  input: UpdateVailSolutionInput,
  condition?: ModelVailSolutionConditionInput | null,
};

export type UpdateVailSolutionMutation = {
  updateVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type CreateRecipeMutationVariables = {
  input: CreateRecipeInput,
  condition?: ModelRecipeConditionInput | null,
};

export type CreateRecipeMutation = {
  createRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type UpdateRecipeMutationVariables = {
  input: UpdateRecipeInput,
  condition?: ModelRecipeConditionInput | null,
};

export type UpdateRecipeMutation = {
  updateRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type UpdateVailReleaseMutationVariables = {
  input: UpdateVailReleaseInput,
  condition?: ModelVailReleaseConditionInput | null,
};

export type UpdateVailReleaseMutation = {
  updateVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type CreatePromptSetMutationVariables = {
  input: CreatePromptSetInput,
  condition?: ModelPromptSetConditionInput | null,
};

export type CreatePromptSetMutation = {
  createPromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type UpdatePromptSetMutationVariables = {
  input: UpdatePromptSetInput,
  condition?: ModelPromptSetConditionInput | null,
};

export type UpdatePromptSetMutation = {
  updatePromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type CreateDictionaryMutationVariables = {
  input: CreateDictionaryInput,
  condition?: ModelDictionaryConditionInput | null,
};

export type CreateDictionaryMutation = {
  createDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDictionaryMutationVariables = {
  input: UpdateDictionaryInput,
  condition?: ModelDictionaryConditionInput | null,
};

export type UpdateDictionaryMutation = {
  updateDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNamespaceMutationVariables = {
  input: CreateNamespaceInput,
  condition?: ModelNamespaceConditionInput | null,
};

export type CreateNamespaceMutation = {
  createNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type UpdateNamespaceMutationVariables = {
  input: UpdateNamespaceInput,
  condition?: ModelNamespaceConditionInput | null,
};

export type UpdateNamespaceMutation = {
  updateNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type CreateDocumentMutationVariables = {
  input: CreateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type UpdateDocumentMutationVariables = {
  input: UpdateDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type UpdateDocumentMutation = {
  updateDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type DeleteDocumentMutationVariables = {
  input: DeleteDocumentInput,
  condition?: ModelDocumentConditionInput | null,
};

export type DeleteDocumentMutation = {
  deleteDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type CreateCustomComponentMutationVariables = {
  input: CreateCustomComponentInput,
  condition?: ModelCustomComponentConditionInput | null,
};

export type CreateCustomComponentMutation = {
  createCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type UpdateCustomComponentMutationVariables = {
  input: UpdateCustomComponentInput,
  condition?: ModelCustomComponentConditionInput | null,
};

export type UpdateCustomComponentMutation = {
  updateCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type CreateWorkshopCacheMutationVariables = {
  input: CreateWorkshopCacheInput,
  condition?: ModelWorkshopCacheConditionInput | null,
};

export type CreateWorkshopCacheMutation = {
  createWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWorkshopCacheMutationVariables = {
  input: UpdateWorkshopCacheInput,
  condition?: ModelWorkshopCacheConditionInput | null,
};

export type UpdateWorkshopCacheMutation = {
  updateWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWorkshopCacheMutationVariables = {
  input: DeleteWorkshopCacheInput,
  condition?: ModelWorkshopCacheConditionInput | null,
};

export type DeleteWorkshopCacheMutation = {
  deleteWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReleaseHistoriesMutationVariables = {
  input: UpdateReleaseHistoriesInput,
  condition?: ModelReleaseHistoriesConditionInput | null,
};

export type UpdateReleaseHistoriesMutation = {
  updateReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetWorkshopSchemaQueryVariables = {
  name: string,
  version: string,
  env: string,
};

export type GetWorkshopSchemaQuery = {
  getWorkshopSchema?: string | null,
};

export type ValidateConfigQueryVariables = {
  env: string,
  component: string,
};

export type ValidateConfigQuery = {
  validateConfig?:  {
    __typename: "Validation",
    valid: boolean,
    error?: string | null,
  } | null,
};

export type GetCognitoUserQueryVariables = {
  id: string,
};

export type GetCognitoUserQuery = {
  getCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCognitoUsersQueryVariables = {
  filter?: ModelCognitoUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCognitoUsersQuery = {
  listCognitoUsers?:  {
    __typename: "ModelCognitoUserConnection",
    items:  Array< {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      status?: JobStatus | null,
      name?: string | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      jobUserId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVailEngagementGoalQueryVariables = {
  id: string,
};

export type GetVailEngagementGoalQuery = {
  getVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type ListVailEngagementGoalsQueryVariables = {
  filter?: ModelVailEngagementGoalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVailEngagementGoalsQuery = {
  listVailEngagementGoals?:  {
    __typename: "ModelVailEngagementGoalConnection",
    items:  Array< {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVailFAQQueryVariables = {
  id: string,
};

export type GetVailFAQQuery = {
  getVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type ListVailFAQSQueryVariables = {
  filter?: ModelVailFAQFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVailFAQSQuery = {
  listVailFAQS?:  {
    __typename: "ModelVailFAQConnection",
    items:  Array< {
      __typename: "VailFAQ",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      active: boolean,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationFAQId?: string | null,
      vailFAQUpdatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVailHistoryQueryVariables = {
  id: string,
};

export type GetVailHistoryQuery = {
  getVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type ListVailHistoriesQueryVariables = {
  filter?: ModelVailHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVailHistoriesQuery = {
  listVailHistories?:  {
    __typename: "ModelVailHistoryConnection",
    items:  Array< {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetEgCardQueryVariables = {
  id: string,
};

export type GetEgCardQuery = {
  getEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type ListEgCardsQueryVariables = {
  filter?: ModelEgCardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEgCardsQuery = {
  listEgCards?:  {
    __typename: "ModelEgCardConnection",
    items:  Array< {
      __typename: "EgCard",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalCardsId?: string | null,
      organizationEgCardsId?: string | null,
      vailSolutionEngagementGoalsId?: string | null,
      egCardVersionId: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVailSolutionQueryVariables = {
  id: string,
};

export type GetVailSolutionQuery = {
  getVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type ListVailSolutionsQueryVariables = {
  filter?: ModelVailSolutionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVailSolutionsQuery = {
  listVailSolutions?:  {
    __typename: "ModelVailSolutionConnection",
    items:  Array< {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRecipeQueryVariables = {
  id: string,
};

export type GetRecipeQuery = {
  getRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type ListRecipesQueryVariables = {
  filter?: ModelRecipeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRecipesQuery = {
  listRecipes?:  {
    __typename: "ModelRecipeConnection",
    items:  Array< {
      __typename: "Recipe",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      scriptRecipe: string,
      scriptCode?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationRecipeId?: string | null,
      recipeUpdatedById?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVailReleaseQueryVariables = {
  id: string,
};

export type GetVailReleaseQuery = {
  getVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type ListVailReleasesQueryVariables = {
  filter?: ModelVailReleaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVailReleasesQuery = {
  listVailReleases?:  {
    __typename: "ModelVailReleaseConnection",
    items:  Array< {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromptSetQueryVariables = {
  id: string,
};

export type GetPromptSetQuery = {
  getPromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type ListPromptSetsQueryVariables = {
  filter?: ModelPromptSetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromptSetsQuery = {
  listPromptSets?:  {
    __typename: "ModelPromptSetConnection",
    items:  Array< {
      __typename: "PromptSet",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      maxTokens?: number | null,
      name: string,
      prompt?: string | null,
      temperature?: number | null,
      type: PromptSetTypes,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationPromptSetsId?: string | null,
      promptSetUpdatedById: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDictionaryQueryVariables = {
  id: string,
};

export type GetDictionaryQuery = {
  getDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDictionariesQueryVariables = {
  filter?: ModelDictionaryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDictionariesQuery = {
  listDictionaries?:  {
    __typename: "ModelDictionaryConnection",
    items:  Array< {
      __typename: "Dictionary",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      medicationName?: string | null,
      typeOfMedication?: string | null,
      customerProfile?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNamespaceQueryVariables = {
  id: string,
};

export type GetNamespaceQuery = {
  getNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type ListNamespacesQueryVariables = {
  filter?: ModelNamespaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNamespacesQuery = {
  listNamespaces?:  {
    __typename: "ModelNamespaceConnection",
    items:  Array< {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDocumentQueryVariables = {
  id: string,
};

export type GetDocumentQuery = {
  getDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type ListDocumentsQueryVariables = {
  filter?: ModelDocumentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDocumentsQuery = {
  listDocuments?:  {
    __typename: "ModelDocumentConnection",
    items:  Array< {
      __typename: "Document",
      type?: DocTypes | null,
      source?: string | null,
      name: string,
      active: boolean,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationDocumentsId?: string | null,
      namespaceDocumentsId?: string | null,
      documentUpdatedById: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomComponentQueryVariables = {
  id: string,
};

export type GetCustomComponentQuery = {
  getCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type ListCustomComponentsQueryVariables = {
  filter?: ModelCustomComponentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomComponentsQuery = {
  listCustomComponents?:  {
    __typename: "ModelCustomComponentConnection",
    items:  Array< {
      __typename: "CustomComponent",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      description: string,
      type: ComponentType,
      settings: string,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationCustomComponentsId?: string | null,
      customComponentUpdatedById: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkshopCacheQueryVariables = {
  id: string,
};

export type GetWorkshopCacheQuery = {
  getWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWorkshopCachesQueryVariables = {
  filter?: ModelWorkshopCacheFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkshopCachesQuery = {
  listWorkshopCaches?:  {
    __typename: "ModelWorkshopCacheConnection",
    items:  Array< {
      __typename: "WorkshopCache",
      hash: string,
      env: string,
      workshopKey: string,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReleaseHistoriesQueryVariables = {
  id: string,
};

export type GetReleaseHistoriesQuery = {
  getReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReleaseHistoriesQueryVariables = {
  filter?: ModelReleaseHistoriesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReleaseHistoriesQuery = {
  listReleaseHistories?:  {
    __typename: "ModelReleaseHistoriesConnection",
    items:  Array< {
      __typename: "ReleaseHistories",
      id: string,
      vailHistoryID: string,
      vailReleaseID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCognitoUserSubscriptionVariables = {
  filter?: ModelSubscriptionCognitoUserFilterInput | null,
  owner?: string | null,
};

export type OnCreateCognitoUserSubscription = {
  onCreateCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCognitoUserSubscriptionVariables = {
  filter?: ModelSubscriptionCognitoUserFilterInput | null,
  owner?: string | null,
};

export type OnUpdateCognitoUserSubscription = {
  onUpdateCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCognitoUserSubscriptionVariables = {
  filter?: ModelSubscriptionCognitoUserFilterInput | null,
  owner?: string | null,
};

export type OnDeleteCognitoUserSubscription = {
  onDeleteCognitoUser?:  {
    __typename: "CognitoUser",
    owner?: string | null,
    email: string,
    name: string,
    role: Permission,
    settings?:  {
      __typename: "UserSettings",
      fontSize?: number | null,
      theme?: Theme | null,
      recipeDelay?: number | null,
    } | null,
    demosShown?: Array< string > | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type OnUpdateJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type OnDeleteJobSubscriptionVariables = {
  filter?: ModelSubscriptionJobFilterInput | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    user?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    status?: JobStatus | null,
    name?: string | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    jobUserId?: string | null,
  } | null,
};

export type OnCreateVailEngagementGoalSubscriptionVariables = {
  filter?: ModelSubscriptionVailEngagementGoalFilterInput | null,
};

export type OnCreateVailEngagementGoalSubscription = {
  onCreateVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type OnUpdateVailEngagementGoalSubscriptionVariables = {
  filter?: ModelSubscriptionVailEngagementGoalFilterInput | null,
};

export type OnUpdateVailEngagementGoalSubscription = {
  onUpdateVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type OnDeleteVailEngagementGoalSubscriptionVariables = {
  filter?: ModelSubscriptionVailEngagementGoalFilterInput | null,
};

export type OnDeleteVailEngagementGoalSubscription = {
  onDeleteVailEngagementGoal?:  {
    __typename: "VailEngagementGoal",
    admins?: string | null,
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    description: string,
    priority: Priority,
    script?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    creation?:  {
      __typename: "CreationEG",
      createInRecipe: boolean,
    } | null,
    archived?: Visibility | null,
    spec: string,
    cards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    notification?: string | null,
    initialConversation?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationEngagementGoalsId?: string | null,
    vailEngagementGoalUpdatedById?: string | null,
  } | null,
};

export type OnCreateVailFAQSubscriptionVariables = {
  filter?: ModelSubscriptionVailFAQFilterInput | null,
};

export type OnCreateVailFAQSubscription = {
  onCreateVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type OnUpdateVailFAQSubscriptionVariables = {
  filter?: ModelSubscriptionVailFAQFilterInput | null,
};

export type OnUpdateVailFAQSubscription = {
  onUpdateVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type OnDeleteVailFAQSubscriptionVariables = {
  filter?: ModelSubscriptionVailFAQFilterInput | null,
};

export type OnDeleteVailFAQSubscription = {
  onDeleteVailFAQ?:  {
    __typename: "VailFAQ",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    autoSuggestConfig:  {
      __typename: "AutoSuggestConfig",
      ignoreWords: Array< string >,
      minimumCharacters: number,
      preSearchPrompt: string,
      questionNotFoundButton: string,
      returnToMainMenuButton: string,
      searchBoxPlaceholder: string,
    },
    menuConfig:  {
      __typename: "FAQMenuConfig",
      answerCompleteButton: Array< string >,
      defaultQuestions: Array< string >,
      disclaimerText: string,
      displayRandomQuestionsInMenu: boolean,
      exitFaqComponentButton: string,
      startSearchButton: string,
      questionNotFoundStep: string,
      faqCompleteNextStep: string,
      menuPrompt: Array< string >,
      persistentQuestion: Array< string >,
    },
    name: string,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    questions?:  Array< {
      __typename: "FAQQuestion",
      disclaimer: boolean,
      key: string,
      id?: string | null,
      keywords: Array< string >,
      question: string,
      suggestedQuestions?: Array< string > | null,
    } > | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    active: boolean,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationFAQId?: string | null,
    vailFAQUpdatedById?: string | null,
  } | null,
};

export type OnCreateVailHistorySubscriptionVariables = {
  filter?: ModelSubscriptionVailHistoryFilterInput | null,
};

export type OnCreateVailHistorySubscription = {
  onCreateVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type OnUpdateVailHistorySubscriptionVariables = {
  filter?: ModelSubscriptionVailHistoryFilterInput | null,
};

export type OnUpdateVailHistorySubscription = {
  onUpdateVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type OnDeleteVailHistorySubscriptionVariables = {
  filter?: ModelSubscriptionVailHistoryFilterInput | null,
};

export type OnDeleteVailHistorySubscription = {
  onDeleteVailHistory?:  {
    __typename: "VailHistory",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    createdBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    data: string,
    name: string,
    notes?: string | null,
    platformKeys?:  {
      __typename: "PlatformKeys",
      lowerEnv?: string | null,
      middleEnv?: string | null,
      upperEnv?: string | null,
    } | null,
    lastDeployedLower?: string | null,
    lastDeployedUpper?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    releases?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalHistoryId?: string | null,
    vailFAQHistoryId?: string | null,
    organizationHistoryId?: string | null,
    vailSolutionHistoryId?: string | null,
    recipeHistoryId?: string | null,
    customComponentHistoryId?: string | null,
    vailHistoryCreatedById?: string | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
  filter?: ModelSubscriptionOrganizationFilterInput | null,
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    solution?:  {
      __typename: "ModelVailSolutionConnection",
      nextToken?: string | null,
    } | null,
    FAQ?:  {
      __typename: "ModelVailFAQConnection",
      nextToken?: string | null,
    } | null,
    engagementGoals?:  {
      __typename: "ModelVailEngagementGoalConnection",
      nextToken?: string | null,
    } | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    name: string,
    orgStatus?: orgStatus | null,
    privacyPolicy?: string | null,
    recipe?:  {
      __typename: "ModelRecipeConnection",
      nextToken?: string | null,
    } | null,
    egCards?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    prodRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    stagingRelease?:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    } | null,
    slug: string,
    tags: Array< string >,
    termsOfUse?: string | null,
    phoneNumber?: string | null,
    customComponents?:  {
      __typename: "ModelCustomComponentConnection",
      nextToken?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    promptSets?:  {
      __typename: "ModelPromptSetConnection",
      nextToken?: string | null,
    } | null,
    namespaces?:  {
      __typename: "ModelNamespaceConnection",
      nextToken?: string | null,
    } | null,
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    solutionDeployed?: string | null,
    languagesSupported?: Array< string > | null,
    lastRelease?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationProdReleaseId?: string | null,
    organizationStagingReleaseId?: string | null,
    organizationUpdatedById?: string | null,
  } | null,
};

export type OnCreateEgCardSubscriptionVariables = {
  filter?: ModelSubscriptionEgCardFilterInput | null,
};

export type OnCreateEgCardSubscription = {
  onCreateEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type OnUpdateEgCardSubscriptionVariables = {
  filter?: ModelSubscriptionEgCardFilterInput | null,
};

export type OnUpdateEgCardSubscription = {
  onUpdateEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type OnDeleteEgCardSubscriptionVariables = {
  filter?: ModelSubscriptionEgCardFilterInput | null,
};

export type OnDeleteEgCardSubscription = {
  onDeleteEgCard?:  {
    __typename: "EgCard",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    engagementGoal:  {
      __typename: "VailEngagementGoal",
      admins?: string | null,
      editors?: string | null,
      readers?: string | null,
      name: string,
      description: string,
      priority: Priority,
      archived?: Visibility | null,
      spec: string,
      notification?: string | null,
      initialConversation?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationEngagementGoalsId?: string | null,
      vailEngagementGoalUpdatedById?: string | null,
    },
    version:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    solution:  {
      __typename: "VailSolution",
      editors?: string | null,
      readers?: string | null,
      name: string,
      type: SolutionType,
      description?: string | null,
      entryPoint: string,
      usesYML?: boolean | null,
      script?: string | null,
      lastDeployed?: string | null,
      visibility?: Visibility | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationSolutionId?: string | null,
      vailSolutionUpdatedById?: string | null,
    },
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    vailEngagementGoalCardsId?: string | null,
    organizationEgCardsId?: string | null,
    vailSolutionEngagementGoalsId?: string | null,
    egCardVersionId: string,
  } | null,
};

export type OnCreateVailSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionVailSolutionFilterInput | null,
};

export type OnCreateVailSolutionSubscription = {
  onCreateVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type OnUpdateVailSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionVailSolutionFilterInput | null,
};

export type OnUpdateVailSolutionSubscription = {
  onUpdateVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type OnDeleteVailSolutionSubscriptionVariables = {
  filter?: ModelSubscriptionVailSolutionFilterInput | null,
};

export type OnDeleteVailSolutionSubscription = {
  onDeleteVailSolution?:  {
    __typename: "VailSolution",
    editors?: string | null,
    readers?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    name: string,
    type: SolutionType,
    description?: string | null,
    entryPoint: string,
    components?:  Array< {
      __typename: "ReleaseBundle",
      name: string,
      version: string,
      type: string,
      extra?: string | null,
    } > | null,
    usesYML?: boolean | null,
    script?: string | null,
    engagementGoals?:  {
      __typename: "ModelEgCardConnection",
      nextToken?: string | null,
    } | null,
    lastDeployed?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationSolutionId?: string | null,
    vailSolutionUpdatedById?: string | null,
  } | null,
};

export type OnCreateRecipeSubscriptionVariables = {
  filter?: ModelSubscriptionRecipeFilterInput | null,
};

export type OnCreateRecipeSubscription = {
  onCreateRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type OnUpdateRecipeSubscriptionVariables = {
  filter?: ModelSubscriptionRecipeFilterInput | null,
};

export type OnUpdateRecipeSubscription = {
  onUpdateRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type OnDeleteRecipeSubscriptionVariables = {
  filter?: ModelSubscriptionRecipeFilterInput | null,
};

export type OnDeleteRecipeSubscription = {
  onDeleteRecipe?:  {
    __typename: "Recipe",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    scriptRecipe: string,
    settings:  {
      __typename: "RecipeSettings",
      description: string,
      name: string,
    },
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    scriptCode?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationRecipeId?: string | null,
    recipeUpdatedById?: string | null,
  } | null,
};

export type OnCreateVailReleaseSubscriptionVariables = {
  filter?: ModelSubscriptionVailReleaseFilterInput | null,
};

export type OnCreateVailReleaseSubscription = {
  onCreateVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type OnUpdateVailReleaseSubscriptionVariables = {
  filter?: ModelSubscriptionVailReleaseFilterInput | null,
};

export type OnUpdateVailReleaseSubscription = {
  onUpdateVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type OnDeleteVailReleaseSubscriptionVariables = {
  filter?: ModelSubscriptionVailReleaseFilterInput | null,
};

export type OnDeleteVailReleaseSubscription = {
  onDeleteVailRelease?:  {
    __typename: "VailRelease",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    version: string,
    name: string,
    createdBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    releaseNotes: string,
    updatedBy?:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    selectedSolutions?:  {
      __typename: "ModelReleaseHistoriesConnection",
      nextToken?: string | null,
    } | null,
    lowerLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    upperLinks?:  Array< {
      __typename: "ReleaseLink",
      name: string,
      url: string,
      componentLink: string,
    } > | null,
    lowerReleaseBundle?: string | null,
    upperReleaseBundle?: string | null,
    publishedToProd?: boolean | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    vailReleaseCreatedById: string,
    vailReleaseUpdatedById?: string | null,
    vailReleaseOrgId?: string | null,
  } | null,
};

export type OnCreatePromptSetSubscriptionVariables = {
  filter?: ModelSubscriptionPromptSetFilterInput | null,
};

export type OnCreatePromptSetSubscription = {
  onCreatePromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type OnUpdatePromptSetSubscriptionVariables = {
  filter?: ModelSubscriptionPromptSetFilterInput | null,
};

export type OnUpdatePromptSetSubscription = {
  onUpdatePromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type OnDeletePromptSetSubscriptionVariables = {
  filter?: ModelSubscriptionPromptSetFilterInput | null,
};

export type OnDeletePromptSetSubscription = {
  onDeletePromptSet?:  {
    __typename: "PromptSet",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    // For chat prompt type, we expect this array to always alternating assistant and user prompts like so:
    // 
    // assistant
    // user
    // assistant
    // user
    // ...
    // 
    // For completion prompt type, this is not used
    chat?:  Array< {
      __typename: "ChatPrompt",
      role: ChatPromptRole,
      response: string,
    } > | null,
    maxTokens?: number | null,
    name: string,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    prompt?: string | null,
    temperature?: number | null,
    type: PromptSetTypes,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationPromptSetsId?: string | null,
    promptSetUpdatedById: string,
  } | null,
};

export type OnCreateDictionarySubscriptionVariables = {
  filter?: ModelSubscriptionDictionaryFilterInput | null,
};

export type OnCreateDictionarySubscription = {
  onCreateDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDictionarySubscriptionVariables = {
  filter?: ModelSubscriptionDictionaryFilterInput | null,
};

export type OnUpdateDictionarySubscription = {
  onUpdateDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDictionarySubscriptionVariables = {
  filter?: ModelSubscriptionDictionaryFilterInput | null,
};

export type OnDeleteDictionarySubscription = {
  onDeleteDictionary?:  {
    __typename: "Dictionary",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    medicationName?: string | null,
    typeOfMedication?: string | null,
    customerProfile?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNamespaceSubscriptionVariables = {
  filter?: ModelSubscriptionNamespaceFilterInput | null,
};

export type OnCreateNamespaceSubscription = {
  onCreateNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type OnUpdateNamespaceSubscriptionVariables = {
  filter?: ModelSubscriptionNamespaceFilterInput | null,
};

export type OnUpdateNamespaceSubscription = {
  onUpdateNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type OnDeleteNamespaceSubscriptionVariables = {
  filter?: ModelSubscriptionNamespaceFilterInput | null,
};

export type OnDeleteNamespaceSubscription = {
  onDeleteNamespace?:  {
    __typename: "Namespace",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    documents?:  {
      __typename: "ModelDocumentConnection",
      nextToken?: string | null,
    } | null,
    // This is the index name of the vector store DB
    indexName?: string | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationNamespacesId?: string | null,
    namespaceUpdatedById: string,
  } | null,
};

export type OnCreateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnCreateDocumentSubscription = {
  onCreateDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type OnUpdateDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnUpdateDocumentSubscription = {
  onUpdateDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type OnDeleteDocumentSubscriptionVariables = {
  filter?: ModelSubscriptionDocumentFilterInput | null,
};

export type OnDeleteDocumentSubscription = {
  onDeleteDocument?:  {
    __typename: "Document",
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    namespace?:  {
      __typename: "Namespace",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      // This is the index name of the vector store DB
      indexName?: string | null,
      status?: ProcessingStatus | null,
      displayStatus?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationNamespacesId?: string | null,
      namespaceUpdatedById: string,
    } | null,
    type?: DocTypes | null,
    source?: string | null,
    name: string,
    active: boolean,
    org:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    },
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationDocumentsId?: string | null,
    namespaceDocumentsId?: string | null,
    documentUpdatedById: string,
  } | null,
};

export type OnCreateCustomComponentSubscriptionVariables = {
  filter?: ModelSubscriptionCustomComponentFilterInput | null,
};

export type OnCreateCustomComponentSubscription = {
  onCreateCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type OnUpdateCustomComponentSubscriptionVariables = {
  filter?: ModelSubscriptionCustomComponentFilterInput | null,
};

export type OnUpdateCustomComponentSubscription = {
  onUpdateCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type OnDeleteCustomComponentSubscriptionVariables = {
  filter?: ModelSubscriptionCustomComponentFilterInput | null,
};

export type OnDeleteCustomComponentSubscription = {
  onDeleteCustomComponent?:  {
    __typename: "CustomComponent",
    editors?: string | null,
    readers?: string | null,
    admins?: string | null,
    org?:  {
      __typename: "Organization",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      name: string,
      orgStatus?: orgStatus | null,
      privacyPolicy?: string | null,
      slug: string,
      tags: Array< string >,
      termsOfUse?: string | null,
      phoneNumber?: string | null,
      solutionDeployed?: string | null,
      languagesSupported?: Array< string > | null,
      lastRelease?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      organizationProdReleaseId?: string | null,
      organizationStagingReleaseId?: string | null,
      organizationUpdatedById?: string | null,
    } | null,
    name: string,
    description: string,
    type: ComponentType,
    settings: string,
    updatedBy:  {
      __typename: "CognitoUser",
      owner?: string | null,
      email: string,
      name: string,
      role: Permission,
      demosShown?: Array< string > | null,
      id: string,
      createdAt: string,
      updatedAt: string,
    },
    history?:  {
      __typename: "ModelVailHistoryConnection",
      nextToken?: string | null,
    } | null,
    status?: ProcessingStatus | null,
    displayStatus?: string | null,
    visibility?: Visibility | null,
    id: string,
    createdAt: string,
    updatedAt: string,
    organizationCustomComponentsId?: string | null,
    customComponentUpdatedById: string,
  } | null,
};

export type OnCreateWorkshopCacheSubscriptionVariables = {
  filter?: ModelSubscriptionWorkshopCacheFilterInput | null,
};

export type OnCreateWorkshopCacheSubscription = {
  onCreateWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWorkshopCacheSubscriptionVariables = {
  filter?: ModelSubscriptionWorkshopCacheFilterInput | null,
};

export type OnUpdateWorkshopCacheSubscription = {
  onUpdateWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWorkshopCacheSubscriptionVariables = {
  filter?: ModelSubscriptionWorkshopCacheFilterInput | null,
};

export type OnDeleteWorkshopCacheSubscription = {
  onDeleteWorkshopCache?:  {
    __typename: "WorkshopCache",
    hash: string,
    env: string,
    workshopKey: string,
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReleaseHistoriesSubscriptionVariables = {
  filter?: ModelSubscriptionReleaseHistoriesFilterInput | null,
};

export type OnCreateReleaseHistoriesSubscription = {
  onCreateReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReleaseHistoriesSubscriptionVariables = {
  filter?: ModelSubscriptionReleaseHistoriesFilterInput | null,
};

export type OnUpdateReleaseHistoriesSubscription = {
  onUpdateReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReleaseHistoriesSubscriptionVariables = {
  filter?: ModelSubscriptionReleaseHistoriesFilterInput | null,
};

export type OnDeleteReleaseHistoriesSubscription = {
  onDeleteReleaseHistories?:  {
    __typename: "ReleaseHistories",
    id: string,
    vailHistoryID: string,
    vailReleaseID: string,
    vailHistory:  {
      __typename: "VailHistory",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      data: string,
      name: string,
      notes?: string | null,
      lastDeployedLower?: string | null,
      lastDeployedUpper?: string | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailEngagementGoalHistoryId?: string | null,
      vailFAQHistoryId?: string | null,
      organizationHistoryId?: string | null,
      vailSolutionHistoryId?: string | null,
      recipeHistoryId?: string | null,
      customComponentHistoryId?: string | null,
      vailHistoryCreatedById?: string | null,
    },
    vailRelease:  {
      __typename: "VailRelease",
      editors?: string | null,
      readers?: string | null,
      admins?: string | null,
      version: string,
      name: string,
      releaseNotes: string,
      lowerReleaseBundle?: string | null,
      upperReleaseBundle?: string | null,
      publishedToProd?: boolean | null,
      id: string,
      createdAt: string,
      updatedAt: string,
      vailReleaseCreatedById: string,
      vailReleaseUpdatedById?: string | null,
      vailReleaseOrgId?: string | null,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
