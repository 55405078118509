import { JsonViewer } from '@textea/json-viewer'

import { useJsonVailTheme } from '@/components/JsonViewerModal'

export type JsonViewerProps = {
  data?: object
  rootName: string
}
export const JsonDataViewer = (props: JsonViewerProps) => {
  const vailTheme = useJsonVailTheme()
  return (
    <JsonViewer
      enableClipboard
      objectSortKeys
      defaultInspectDepth={100}
      displayDataTypes={false}
      editable={false}
      quotesOnKeys={true}
      rootName={props.rootName}
      theme={vailTheme}
      value={props.data ?? {}}
    />
  )
}
