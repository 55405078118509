/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../utils/api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getWorkshopSchema = /* GraphQL */ `query GetWorkshopSchema($name: String!, $version: String!, $env: String!) {
  getWorkshopSchema(name: $name, version: $version, env: $env)
}
` as GeneratedQuery<
  APITypes.GetWorkshopSchemaQueryVariables,
  APITypes.GetWorkshopSchemaQuery
>;
export const validateConfig = /* GraphQL */ `query ValidateConfig($env: String!, $component: String!) {
  validateConfig(env: $env, component: $component) {
    valid
    error
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ValidateConfigQueryVariables,
  APITypes.ValidateConfigQuery
>;
export const getCognitoUser = /* GraphQL */ `query GetCognitoUser($id: ID!) {
  getCognitoUser(id: $id) {
    owner
    email
    name
    role
    settings {
      fontSize
      theme
      recipeDelay
      __typename
    }
    demosShown
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCognitoUserQueryVariables,
  APITypes.GetCognitoUserQuery
>;
export const listCognitoUsers = /* GraphQL */ `query ListCognitoUsers(
  $filter: ModelCognitoUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listCognitoUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCognitoUsersQueryVariables,
  APITypes.ListCognitoUsersQuery
>;
export const getJob = /* GraphQL */ `query GetJob($id: ID!) {
  getJob(id: $id) {
    user {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    status
    name
    displayStatus
    id
    createdAt
    updatedAt
    jobUserId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetJobQueryVariables, APITypes.GetJobQuery>;
export const listJobs = /* GraphQL */ `query ListJobs($filter: ModelJobFilterInput, $limit: Int, $nextToken: String) {
  listJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      status
      name
      displayStatus
      id
      createdAt
      updatedAt
      jobUserId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListJobsQueryVariables, APITypes.ListJobsQuery>;
export const getVailEngagementGoal = /* GraphQL */ `query GetVailEngagementGoal($id: ID!) {
  getVailEngagementGoal(id: $id) {
    admins
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    description
    priority
    script {
      name
      version
      type
      extra
      __typename
    }
    creation {
      createInRecipe
      __typename
    }
    archived
    spec
    cards {
      nextToken
      __typename
    }
    notification
    initialConversation
    id
    createdAt
    updatedAt
    organizationEngagementGoalsId
    vailEngagementGoalUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVailEngagementGoalQueryVariables,
  APITypes.GetVailEngagementGoalQuery
>;
export const listVailEngagementGoals = /* GraphQL */ `query ListVailEngagementGoals(
  $filter: ModelVailEngagementGoalFilterInput
  $limit: Int
  $nextToken: String
) {
  listVailEngagementGoals(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      admins
      editors
      readers
      name
      description
      priority
      archived
      spec
      notification
      initialConversation
      id
      createdAt
      updatedAt
      organizationEngagementGoalsId
      vailEngagementGoalUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVailEngagementGoalsQueryVariables,
  APITypes.ListVailEngagementGoalsQuery
>;
export const getVailFAQ = /* GraphQL */ `query GetVailFAQ($id: ID!) {
  getVailFAQ(id: $id) {
    editors
    readers
    admins
    autoSuggestConfig {
      ignoreWords
      minimumCharacters
      preSearchPrompt
      questionNotFoundButton
      returnToMainMenuButton
      searchBoxPlaceholder
      __typename
    }
    menuConfig {
      answerCompleteButton
      defaultQuestions
      disclaimerText
      displayRandomQuestionsInMenu
      exitFaqComponentButton
      startSearchButton
      questionNotFoundStep
      faqCompleteNextStep
      menuPrompt
      persistentQuestion
      __typename
    }
    name
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    questions {
      disclaimer
      key
      id
      keywords
      question
      suggestedQuestions
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    active
    id
    createdAt
    updatedAt
    organizationFAQId
    vailFAQUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVailFAQQueryVariables,
  APITypes.GetVailFAQQuery
>;
export const listVailFAQS = /* GraphQL */ `query ListVailFAQS(
  $filter: ModelVailFAQFilterInput
  $limit: Int
  $nextToken: String
) {
  listVailFAQS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      name
      active
      id
      createdAt
      updatedAt
      organizationFAQId
      vailFAQUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVailFAQSQueryVariables,
  APITypes.ListVailFAQSQuery
>;
export const getVailHistory = /* GraphQL */ `query GetVailHistory($id: ID!) {
  getVailHistory(id: $id) {
    editors
    readers
    admins
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    data
    name
    notes
    platformKeys {
      lowerEnv
      middleEnv
      upperEnv
      __typename
    }
    lastDeployedLower
    lastDeployedUpper
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    releases {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalHistoryId
    vailFAQHistoryId
    organizationHistoryId
    vailSolutionHistoryId
    recipeHistoryId
    customComponentHistoryId
    vailHistoryCreatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVailHistoryQueryVariables,
  APITypes.GetVailHistoryQuery
>;
export const listVailHistories = /* GraphQL */ `query ListVailHistories(
  $filter: ModelVailHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listVailHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      data
      name
      notes
      lastDeployedLower
      lastDeployedUpper
      id
      createdAt
      updatedAt
      vailEngagementGoalHistoryId
      vailFAQHistoryId
      organizationHistoryId
      vailSolutionHistoryId
      recipeHistoryId
      customComponentHistoryId
      vailHistoryCreatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVailHistoriesQueryVariables,
  APITypes.ListVailHistoriesQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    editors
    readers
    admins
    solution {
      nextToken
      __typename
    }
    FAQ {
      nextToken
      __typename
    }
    engagementGoals {
      nextToken
      __typename
    }
    history {
      nextToken
      __typename
    }
    name
    orgStatus
    privacyPolicy
    recipe {
      nextToken
      __typename
    }
    egCards {
      nextToken
      __typename
    }
    prodRelease {
      editors
      readers
      admins
      version
      name
      releaseNotes
      lowerReleaseBundle
      upperReleaseBundle
      publishedToProd
      id
      createdAt
      updatedAt
      vailReleaseCreatedById
      vailReleaseUpdatedById
      vailReleaseOrgId
      __typename
    }
    stagingRelease {
      editors
      readers
      admins
      version
      name
      releaseNotes
      lowerReleaseBundle
      upperReleaseBundle
      publishedToProd
      id
      createdAt
      updatedAt
      vailReleaseCreatedById
      vailReleaseUpdatedById
      vailReleaseOrgId
      __typename
    }
    slug
    tags
    termsOfUse
    phoneNumber
    customComponents {
      nextToken
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    promptSets {
      nextToken
      __typename
    }
    namespaces {
      nextToken
      __typename
    }
    documents {
      nextToken
      __typename
    }
    solutionDeployed
    languagesSupported
    lastRelease
    id
    createdAt
    updatedAt
    organizationProdReleaseId
    organizationStagingReleaseId
    organizationUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const getEgCard = /* GraphQL */ `query GetEgCard($id: ID!) {
  getEgCard(id: $id) {
    editors
    readers
    admins
    engagementGoal {
      admins
      editors
      readers
      name
      description
      priority
      archived
      spec
      notification
      initialConversation
      id
      createdAt
      updatedAt
      organizationEngagementGoalsId
      vailEngagementGoalUpdatedById
      __typename
    }
    version {
      editors
      readers
      admins
      data
      name
      notes
      lastDeployedLower
      lastDeployedUpper
      id
      createdAt
      updatedAt
      vailEngagementGoalHistoryId
      vailFAQHistoryId
      organizationHistoryId
      vailSolutionHistoryId
      recipeHistoryId
      customComponentHistoryId
      vailHistoryCreatedById
      __typename
    }
    solution {
      editors
      readers
      name
      type
      description
      entryPoint
      usesYML
      script
      lastDeployed
      visibility
      id
      createdAt
      updatedAt
      organizationSolutionId
      vailSolutionUpdatedById
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    id
    createdAt
    updatedAt
    vailEngagementGoalCardsId
    organizationEgCardsId
    vailSolutionEngagementGoalsId
    egCardVersionId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEgCardQueryVariables, APITypes.GetEgCardQuery>;
export const listEgCards = /* GraphQL */ `query ListEgCards(
  $filter: ModelEgCardFilterInput
  $limit: Int
  $nextToken: String
) {
  listEgCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      id
      createdAt
      updatedAt
      vailEngagementGoalCardsId
      organizationEgCardsId
      vailSolutionEngagementGoalsId
      egCardVersionId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEgCardsQueryVariables,
  APITypes.ListEgCardsQuery
>;
export const getVailSolution = /* GraphQL */ `query GetVailSolution($id: ID!) {
  getVailSolution(id: $id) {
    editors
    readers
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    name
    type
    description
    entryPoint
    components {
      name
      version
      type
      extra
      __typename
    }
    usesYML
    script
    engagementGoals {
      nextToken
      __typename
    }
    lastDeployed
    visibility
    id
    createdAt
    updatedAt
    organizationSolutionId
    vailSolutionUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVailSolutionQueryVariables,
  APITypes.GetVailSolutionQuery
>;
export const listVailSolutions = /* GraphQL */ `query ListVailSolutions(
  $filter: ModelVailSolutionFilterInput
  $limit: Int
  $nextToken: String
) {
  listVailSolutions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      name
      type
      description
      entryPoint
      usesYML
      script
      lastDeployed
      visibility
      id
      createdAt
      updatedAt
      organizationSolutionId
      vailSolutionUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVailSolutionsQueryVariables,
  APITypes.ListVailSolutionsQuery
>;
export const getRecipe = /* GraphQL */ `query GetRecipe($id: ID!) {
  getRecipe(id: $id) {
    editors
    readers
    admins
    history {
      nextToken
      __typename
    }
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    scriptRecipe
    settings {
      description
      name
      __typename
    }
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    scriptCode
    id
    createdAt
    updatedAt
    organizationRecipeId
    recipeUpdatedById
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRecipeQueryVariables, APITypes.GetRecipeQuery>;
export const listRecipes = /* GraphQL */ `query ListRecipes(
  $filter: ModelRecipeFilterInput
  $limit: Int
  $nextToken: String
) {
  listRecipes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      scriptRecipe
      scriptCode
      id
      createdAt
      updatedAt
      organizationRecipeId
      recipeUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRecipesQueryVariables,
  APITypes.ListRecipesQuery
>;
export const getVailRelease = /* GraphQL */ `query GetVailRelease($id: ID!) {
  getVailRelease(id: $id) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    version
    name
    createdBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    releaseNotes
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    selectedSolutions {
      nextToken
      __typename
    }
    lowerLinks {
      name
      url
      componentLink
      __typename
    }
    upperLinks {
      name
      url
      componentLink
      __typename
    }
    lowerReleaseBundle
    upperReleaseBundle
    publishedToProd
    id
    createdAt
    updatedAt
    vailReleaseCreatedById
    vailReleaseUpdatedById
    vailReleaseOrgId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVailReleaseQueryVariables,
  APITypes.GetVailReleaseQuery
>;
export const listVailReleases = /* GraphQL */ `query ListVailReleases(
  $filter: ModelVailReleaseFilterInput
  $limit: Int
  $nextToken: String
) {
  listVailReleases(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      version
      name
      releaseNotes
      lowerReleaseBundle
      upperReleaseBundle
      publishedToProd
      id
      createdAt
      updatedAt
      vailReleaseCreatedById
      vailReleaseUpdatedById
      vailReleaseOrgId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVailReleasesQueryVariables,
  APITypes.ListVailReleasesQuery
>;
export const getPromptSet = /* GraphQL */ `query GetPromptSet($id: ID!) {
  getPromptSet(id: $id) {
    editors
    readers
    admins
    chat {
      role
      response
      __typename
    }
    maxTokens
    name
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    prompt
    temperature
    type
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    id
    createdAt
    updatedAt
    organizationPromptSetsId
    promptSetUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPromptSetQueryVariables,
  APITypes.GetPromptSetQuery
>;
export const listPromptSets = /* GraphQL */ `query ListPromptSets(
  $filter: ModelPromptSetFilterInput
  $limit: Int
  $nextToken: String
) {
  listPromptSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      maxTokens
      name
      prompt
      temperature
      type
      id
      createdAt
      updatedAt
      organizationPromptSetsId
      promptSetUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPromptSetsQueryVariables,
  APITypes.ListPromptSetsQuery
>;
export const getDictionary = /* GraphQL */ `query GetDictionary($id: ID!) {
  getDictionary(id: $id) {
    editors
    readers
    admins
    medicationName
    typeOfMedication
    customerProfile
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDictionaryQueryVariables,
  APITypes.GetDictionaryQuery
>;
export const listDictionaries = /* GraphQL */ `query ListDictionaries(
  $filter: ModelDictionaryFilterInput
  $limit: Int
  $nextToken: String
) {
  listDictionaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      medicationName
      typeOfMedication
      customerProfile
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDictionariesQueryVariables,
  APITypes.ListDictionariesQuery
>;
export const getNamespace = /* GraphQL */ `query GetNamespace($id: ID!) {
  getNamespace(id: $id) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    name
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    documents {
      nextToken
      __typename
    }
    indexName
    status
    displayStatus
    id
    createdAt
    updatedAt
    organizationNamespacesId
    namespaceUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNamespaceQueryVariables,
  APITypes.GetNamespaceQuery
>;
export const listNamespaces = /* GraphQL */ `query ListNamespaces(
  $filter: ModelNamespaceFilterInput
  $limit: Int
  $nextToken: String
) {
  listNamespaces(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      name
      indexName
      status
      displayStatus
      id
      createdAt
      updatedAt
      organizationNamespacesId
      namespaceUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNamespacesQueryVariables,
  APITypes.ListNamespacesQuery
>;
export const getDocument = /* GraphQL */ `query GetDocument($id: ID!) {
  getDocument(id: $id) {
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    namespace {
      editors
      readers
      admins
      name
      indexName
      status
      displayStatus
      id
      createdAt
      updatedAt
      organizationNamespacesId
      namespaceUpdatedById
      __typename
    }
    type
    source
    name
    active
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationDocumentsId
    namespaceDocumentsId
    documentUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentQueryVariables,
  APITypes.GetDocumentQuery
>;
export const listDocuments = /* GraphQL */ `query ListDocuments(
  $filter: ModelDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      type
      source
      name
      active
      status
      displayStatus
      visibility
      id
      createdAt
      updatedAt
      organizationDocumentsId
      namespaceDocumentsId
      documentUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;
export const getCustomComponent = /* GraphQL */ `query GetCustomComponent($id: ID!) {
  getCustomComponent(id: $id) {
    editors
    readers
    admins
    org {
      editors
      readers
      admins
      name
      orgStatus
      privacyPolicy
      slug
      tags
      termsOfUse
      phoneNumber
      solutionDeployed
      languagesSupported
      lastRelease
      id
      createdAt
      updatedAt
      organizationProdReleaseId
      organizationStagingReleaseId
      organizationUpdatedById
      __typename
    }
    name
    description
    type
    settings
    updatedBy {
      owner
      email
      name
      role
      demosShown
      id
      createdAt
      updatedAt
      __typename
    }
    history {
      nextToken
      __typename
    }
    status
    displayStatus
    visibility
    id
    createdAt
    updatedAt
    organizationCustomComponentsId
    customComponentUpdatedById
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomComponentQueryVariables,
  APITypes.GetCustomComponentQuery
>;
export const listCustomComponents = /* GraphQL */ `query ListCustomComponents(
  $filter: ModelCustomComponentFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      editors
      readers
      admins
      name
      description
      type
      settings
      status
      displayStatus
      visibility
      id
      createdAt
      updatedAt
      organizationCustomComponentsId
      customComponentUpdatedById
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomComponentsQueryVariables,
  APITypes.ListCustomComponentsQuery
>;
export const getWorkshopCache = /* GraphQL */ `query GetWorkshopCache($id: ID!) {
  getWorkshopCache(id: $id) {
    hash
    env
    workshopKey
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkshopCacheQueryVariables,
  APITypes.GetWorkshopCacheQuery
>;
export const listWorkshopCaches = /* GraphQL */ `query ListWorkshopCaches(
  $filter: ModelWorkshopCacheFilterInput
  $limit: Int
  $nextToken: String
) {
  listWorkshopCaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      hash
      env
      workshopKey
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorkshopCachesQueryVariables,
  APITypes.ListWorkshopCachesQuery
>;
export const getReleaseHistories = /* GraphQL */ `query GetReleaseHistories($id: ID!) {
  getReleaseHistories(id: $id) {
    id
    vailHistoryID
    vailReleaseID
    vailHistory {
      editors
      readers
      admins
      data
      name
      notes
      lastDeployedLower
      lastDeployedUpper
      id
      createdAt
      updatedAt
      vailEngagementGoalHistoryId
      vailFAQHistoryId
      organizationHistoryId
      vailSolutionHistoryId
      recipeHistoryId
      customComponentHistoryId
      vailHistoryCreatedById
      __typename
    }
    vailRelease {
      editors
      readers
      admins
      version
      name
      releaseNotes
      lowerReleaseBundle
      upperReleaseBundle
      publishedToProd
      id
      createdAt
      updatedAt
      vailReleaseCreatedById
      vailReleaseUpdatedById
      vailReleaseOrgId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReleaseHistoriesQueryVariables,
  APITypes.GetReleaseHistoriesQuery
>;
export const listReleaseHistories = /* GraphQL */ `query ListReleaseHistories(
  $filter: ModelReleaseHistoriesFilterInput
  $limit: Int
  $nextToken: String
) {
  listReleaseHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      vailHistoryID
      vailReleaseID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReleaseHistoriesQueryVariables,
  APITypes.ListReleaseHistoriesQuery
>;
